const EditorMenuElement = require("./EditorMenuElement")

module.exports = class EditorMenu {


  constructor() {

    /** @type {EditorMenuElement[]} */
    this.simple

    /** @type {EditorMenuElement[]} */
    this.advanced
  }


}
