const Element = require("./Element");

/**
 * Class representing an video element in Ad-Creative Editor.
 */
module.exports = class Video extends Element {
  /**
   * Create video
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer, id) {
    super(layer, "video", id);
    this.isVideo = true;
    this.settings = {
      altText: "Your browser doesn't support HTML video.",
      src: "https://samplelib.com/lib/preview/mp4/sample-5s.mp4",
      type: "video/mp4",
      controls: true,
      autoplay: true,
    };
  }

  /**
   *
   * @param {string} url
   * @returns {Video} video object
   */
  renderFromUrl(url) {
    if (this.isValidUrl(url)) {
      this.renderElement(url);
      this.url = url;
    }

    return this;
  }

  /**
   *
   * @param {string} src
   */
  renderElement(src) {
    if (this.isValidUrl(src)) {
      var video = document.querySelector("." + this.id);
      var source = document.createElement("source");
      var type = "";

      if (src.includes(".mp4")) {
        type = "video/mp4";
      } else if (src.includes(".webm")) {
        type = "video/webm";
      }
      if (src.includes(".ogg") || src.includes(".ogv")) {
        type = "video/ogg";
      }

      this.settings.src = src;
      this.settings.type = type;

      video.setAttribute("muted", ""); //ALP did it. arka planda edit mode'ta video sesi geliyordu geçiçi çözüm
      source.setAttribute("src", src);
      source.setAttribute("type", type);
      video.appendChild(source);
    }
  }

  isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
};
