const Utils = require("../services/Utils").prototype;
const $ = require("jquery");
const Animation = require("./Animation");

const ELEMENT_TEMPLATES = require("../consts/ElementTemplates");
const ELEMENT_STYLES = require("../consts/ElementStyleConsts");

/**
 * Class representing an element in Ad-Creative Editor
 */
module.exports = class Element {
  /**
   * Create element
   * @param {string} type - Type of element
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer, type, id) {
    this.type = type;
    this.id = id ? id : Utils.generateId(type);
    this.name = this.id;
    /** @type {Animation[]} */
    this.animations = [];

    this.settings = {};

    this.keepRatio = true;
    this.aspectRatio = true;

    /** @type {string} elements */
    this.clickTagUrl = "";
    this.hasClickTag = false;

    if (type == "layer") {
      Utils.drawTemplate(ELEMENT_TEMPLATES[type], { id: this.id }, "#viewport");
    } else {
      Utils.drawTemplate(
        ELEMENT_TEMPLATES[type],
        { id: this.id },
        "." + layer.id
      );
      layer.addElement(this);
    }
    let shapeStyle = {
      ...ELEMENT_STYLES.shape,
      width: global._app.options.canvasOptions.width + "px",
      height: global._app.options.canvasOptions.height + "px",
    };
    let initStyle = { ...ELEMENT_STYLES, shape: shapeStyle };
    this.setStyle(initStyle[type]);
    this.text = document.querySelector("." + this.id).textContent;
  }

  /**
   * Set the style of element
   * @param {Object} style - style object of element
   */
  setStyle(style) {
    this.style = style;
    for (let prop in this.style) {
      $("." + this.id).css(prop, this.style[prop]);
    }
  }

  /**
   * Set the keepRatio of the element
   * @param {boolean} keepRatio
   */
  setKeepRatio(value) {
    this.keepRatio = value;
  }

  /**
   * Set the text of the element
   * @param {string} text
   */
  setText(text) {
    let element = document.querySelector("." + this.id);
    element.textContent = text;
    this.text = text;
  }

  /**
   *
   * @param {string} name of layer
   */
  setName(name) {
    this.name = name;
  }

  /**
   *
   * @param {boolean} aspectRatio 
   */
  setAspectRatio(aspectRatio) {
    this.aspectRatio = aspectRatio;
    global.moveable.keepRatio = aspectRatio;
  }

  /**
   *
   * @returns name of layer
   */
  getName() {
    return this.name;
  }

  /**
   * Get the text of the element
   * @returns {string} inner text of element
   */
  getText() {
    let element = document.querySelector("." + this.id);
    return element.textContent;
  }

    /**
   *
   * @returns value of keeping ratio
   */
    getKeepRatio() {
      return this.keepRatio;
    }
  

  /**
   * Get the style of the element
   * @returns {Map} - style object of element
   */
  getStyle() {
    this.updateStyle();
    return this.style;
  }

  /**
   * Delete element from layer and document
   */
  delete() {
    global._app.layers.forEach((layer) => {
      let index = layer.elements.findIndex((element) => element.id == this.id);
      if (index >= 0) {
        layer.elements.splice(index, 1);
        document.querySelector("." + this.id).remove();
        return;
      }
    });
  }

  /**
   * Make element visible
   */
  show() {
    $("." + this.id).show();
    global.moveable.target = [];
  }

  /**
   * Make element invisible
   */
  hide() {
    $("." + this.id).hide();
  }

  isVisible() {
    return $("." + this.id).is(":visible");
  }

  /**
   *
   * @param {string} type
   * @param {string} delay
   * @param {string} repeat
   * @param {string} duration
   */
  addAnimation(type, delay = "0s", repeat = "1", duration = "1s") {
    let animation = new Animation(type, delay, repeat, duration);
    this.animations.push(animation);
    return animation;
  }

  /**
   *
   * @returns {Animation[]}
   */
  getAnimations() {
    return this.animations;
  }

  /**
   *
   */
  updateStyle() {
    let el = document.querySelector("." + this.id);
    let clonedEl = el.cloneNode(true);
    if (this.type == "button") {
      clonedEl.style.cursor = "pointer";
    }
    if (this.type == "video") {
      if (this.settings.controls) {
        clonedEl.setAttribute("controls", "");
      }
      if (this.settings.autoplay) {
        clonedEl.setAttribute("autoplay", "");
        clonedEl.setAttribute("muted", "");
      }
      /** @type {string} */
      this.videoHTML = clonedEl.outerHTML;
      this.videoHTML = this.videoHTML.replace(`controls=""`, "controls");
      this.videoHTML = this.videoHTML.replace(`autoplay=""`, "autoplay");
      this.videoHTML = this.videoHTML.replace(`muted=""`, "muted");
    }

    this.hasClickTag = this.isValidUrl(this.clickTagUrl);
    this.style = {};
    this.cssText = clonedEl.style.cssText;

    for (let i = 0; i < clonedEl.style.length; i++) {
      let prop = clonedEl.style[i];
      this.style[prop] = clonedEl.style[prop];
    }
  }

  generateAnimationCssText() {
    if (this.animations.length > 0) {
      this.animationObject = {
        names: [],
        durations: [],
        repeats: [],
        delays: [],
      };

      this.animations.forEach((animation) => {
        this.animationObject.names.push(animation.type + ",");
        this.animationObject.durations.push(animation.duration + ",");
        this.animationObject.repeats.push(animation.repeat + ",");
        this.animationObject.delays.push(animation.delay + ",");
      });

      this.removeLastCharInLastElementOfArray(this.animationObject.names);
      this.removeLastCharInLastElementOfArray(this.animationObject.durations);
      this.removeLastCharInLastElementOfArray(this.animationObject.repeats);
      this.removeLastCharInLastElementOfArray(this.animationObject.delays);
    } else {
      this.animationObject = null;
    }
  }

  /**
   *
   * @param {string[]} arr
   * @returns
   */
  removeLastCharInLastElementOfArray(arr) {
    let lastElement = arr.at(-1);
    let lastElementWithoutComma = lastElement.slice(0, -1);
    arr.pop();
    arr.push(lastElementWithoutComma);
  }

  getTranslateXY(element) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      x: matrix.m41,
      y: matrix.m42,
    };
  }

  /**
   *
   * @param {string} url
   */
  setClickTagUrl(url) {
    if (this.isValidUrl(url)) {
      this.clickTagUrl = url;
    }
  }

  /**
   *
   * @returns cligtag urlka
   */
  getClickTagUrl() {
    return this.clickTagUrl;
  }

  isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
};
