import http from "./http-common";
import httpForm from "./http-multidata";

const CREATIVE_BASE = "/creatives";

const getCreativeById = (creativeId) => {
  return http.get(CREATIVE_BASE + `/${creativeId}`);
};

const updateCreativeById = (creativeId, updatedData) => {
  return http.put(CREATIVE_BASE + `/${creativeId}`, updatedData);
};

const updateCreativeFile = (creativeId, fileId, updatedData) => {
  return httpForm.put(CREATIVE_BASE + `/${creativeId}/file/${fileId}`, updatedData);
};

const deleteCreativeFile = (creativeId, fileId) => {
  return http.delete(CREATIVE_BASE + `/${creativeId}/file/${fileId}`);
};

const updateCreativeJson = (creativeId, updatedJson) => {
  return http.put(CREATIVE_BASE + `/${creativeId}/editor`, updatedJson);
};

const getCreatives = (creativeParams) => {
  return http.get(CREATIVE_BASE, {
    params: creativeParams,
  });
};

const createCreative = (creativeData) => {
  //If creating a new creative in a project, fill in the projectId. Creative is created within the current project. Do not use Project id if creating creative from sandbox or directly from the new section.
  return http.post(CREATIVE_BASE, creativeData);
};

const deleteCreative = (creativeId) => {
  return http.delete(CREATIVE_BASE, {
    params: {
      id: creativeId,
    },
  });
};

const createCreativeFile = (creativeId, fileData) => {
  return httpForm.post(CREATIVE_BASE + `/${creativeId}/file`, fileData);
};

const downloadCreative = (creativeId, compressStatus) => {
  return http.get(CREATIVE_BASE + `/${creativeId}/download`, {
    params: {
      compress: compressStatus,
    },
  });
};

const CreativeService = {
  getCreativeById,
  updateCreativeById,
  updateCreativeFile,
  deleteCreativeFile,
  updateCreativeJson,
  getCreatives,
  createCreative,
  deleteCreative,
  createCreativeFile,
  downloadCreative,
};

export default CreativeService;
