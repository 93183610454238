import React from "react";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material";

import "./ButtonPagination.scss";

const StyledPagination = styled(Pagination)(({ theme }) => ({
  // Customize the styles for the active Page button
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: "rgba(255,255,255,0.2)",
  },
}));

const ButtonPagination = ({ page, setPage, totalPage }) => {
  
  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  const isShown = totalPage > 1;

  return (
    <>
      {isShown && (
        <div className="pagination">
          <StyledPagination
            sx={{ justifyContent: "center" }}
            size="small"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
};

export default ButtonPagination;
