
module.exports = class EditorElementOptions {

  constructor() {

    /** @type {Number} */
    this.counter

    /** @type {Number} */
    this.maxElementCount
  }

}
