const $ = require("jquery");
const Mustache = require("mustache");
const EditorElementOptions = require("../models/editor/EditorElementOptions");

module.exports = class Utils {

  /**
   * 
   * @returns {string} type of element
   */
  generateId(type) {
    /** @type {EditorElementOptions} */
    let options = global._app.options.elementOptions;
    return type + "-" + ++options.counter;
  }

  /**
   * 
   * @param {string} template 
   * @param {Object} data 
   * @param {string} id 
   * @returns 
   */
  drawTemplate(template, data, id) {
    let rendered = Mustache.render(template, data);
    $(id).append(rendered);
    return rendered;
  }


}
