import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import logo from "../../assets/logo/saatteknoloji_logo.png";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import MouseIcon from "@mui/icons-material/Mouse";
import ShareIcon from "@mui/icons-material/Share";
import "./LivePreview.scss";
import NavigationIcon from "@mui/icons-material/Navigation";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


const columns = [
  { id: "time", label: "Time", minWidth: 170 },
  { id: "bannerId", label: "Banner\u00a0ID", minWidth: 100 },
  {
    id: "eventId",
    label: "Event\u00a0ID",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "eventName",
    label: "Event\u00a0Name",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const columns2 = [
  { id: "banner", label: "Banner", minWidth: 100 },
  { id: "dimensions", label: "Dimensions", minWidth: 170 },
  {
    id: "xPosition",
    label: "X\u00a0position",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "yPosition",
    label: "Y\u00a0position",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "zIndex",
    label: "Z-Index",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "parentBanner",
    label: "Parent Banner",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(time, bannerID, eventID, eventName) {
  return { time, bannerID, eventID, eventName };
}

function createData2(
  banner,
  dimensions,
  xPosition,
  yPosition,
  zIndex,
  parentBanner
) {
  return { banner, dimensions, xPosition, yPosition, zIndex, parentBanner };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const rows2 = [
  createData2("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData2("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData2("Eclair", 262, 16.0, 24, 6.0),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
  createData2("Cupcake", 305, 3.7, 67, 4.3),
  createData2("Gingerbread", 356, 16.0, 49, 3.9),
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const QrIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60ch",
    },
  },
}));

const LivePreview = () => {
  const [enteredUrl, setEnteredUrl] = useState("https://");
  const [eventLoggerOn, setEventLoggerOn] = useState(false);
  const [positionModeOn, setPositionModeOn] = useState(false);

  const handleEnteredUrl = (event) => {
    setEnteredUrl(event.target.value);
  };

  const handleEventLogger = () => {
    setEventLoggerOn((prevState) => !prevState);
    positionModeOn && setPositionModeOn(false);
  };

  const handlePositionMode = () => {
    setPositionModeOn((prevState) => !prevState);
    eventLoggerOn && setEventLoggerOn(false);
  };

  const eventLogStyle = eventLoggerOn ? {} : { display: "none" };
  const positionModeStyle = positionModeOn ? {} : { display: "none" };

  return (
    <div className="live-preview-container">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <img
              src={logo}
              alt="saat-logo"
              style={{ width: "2rem", height: "2rem" }}
            ></img>

            <Search>
              <QrIconWrapper>
                <QrCode2Icon />
              </QrIconWrapper>
              <StyledInputBase
                placeholder="Enter Url…"
                onChange={handleEnteredUrl}
                value={enteredUrl}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <Fab
                size="small"
                style={{ padding: "0rem 1rem" }}
                variant="extended"
              >
                <NavigationIcon />
                Go
              </Fab>
              <div>
                <Fab
                  onClick={handlePositionMode}
                  style={{ padding: "0rem 1rem" }}
                  size="small"
                  variant="extended"
                >
                  <PictureInPictureAltIcon style={{ marginRight: "0.3rem" }} />
                  Positioning Mode
                </Fab>
                <Fab
                  onClick={handleEventLogger}
                  size="small"
                  style={{ marginLeft: "0.5rem", padding: "0rem 1rem" }}
                  variant="extended"
                >
                  <MouseIcon style={{ marginRight: "0.2rem" }} />
                  Event Logger
                </Fab>
              </div>
              <Fab size="small" variant="extended">
                <ShareIcon style={{ marginRight: "0.2rem" }} />
                Share Preview
              </Fab>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <div className="event-log-table" style={eventLogStyle}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "15rem" }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead size="small">
                <TableRow size="small">
                  <TableCell
                    align="left"
                    colSpan={1}
                    size="small"
                    style={{
                      backgroundColor: "rgba(0,0,0, 0.7)",
                      color: "#ffffff",
                    }}
                  >
                    Event Logger
                  </TableCell>
                  <TableCell
                    align="left"
                    colSpan={3}
                    style={{
                      backgroundColor: "rgba(0,0,0, 0.7)",
                      color: "#ffffff",
                    }}
                  >
                    <Fab
                      size="small"
                      style={{ marginLeft: "0.5rem", padding: "0rem 1rem" }}
                      variant="extended"
                    >
                      Auto scroll List
                    </Fab>
                    <Fab
                      size="small"
                      style={{ marginLeft: "0.5rem", padding: "0rem 1rem" }}
                      variant="extended"
                    >
                      Clear output
                    </Fab>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={Math.random()}
                      align={column.align}
                      style={{
                        top: 45,
                        minWidth: column.minWidth,
                        backgroundColor: "rgba(0,0,0, 0.5)",
                        color: "#ffffff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={Math.random()}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={Math.random()} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <div className="pos-mode-table" style={positionModeStyle}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "15rem" }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead size="small">
                <TableRow size="small">
                  <TableCell
                    align="left"
                    colSpan={1}
                    size="small"
                    style={{
                      backgroundColor: "rgba(0,0,0, 0.7)",
                      color: "#ffffff",
                    }}
                  >
                    Custom Positioning
                  </TableCell>
                  <TableCell
                    align="left"
                    colSpan={7}
                    style={{
                      backgroundColor: "rgba(0,0,0, 0.7)",
                      color: "#ffffff",
                    }}
                  >
                   
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns2.map((column) => (
                    <TableCell
                      key={Math.random()}
                      align={column.align}
                      style={{
                        top: 33,
                        minWidth: column.minWidth,
                        backgroundColor: "rgba(0,0,0, 0.5)",
                        color: "#ffffff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={Math.random()}
                    >
                      {columns2.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={Math.random()} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default LivePreview;
