const Element = require("./Element");
const Layer = require("./Layer").prototype;

/** 
 * Class representing a text element in Ad-Creative Editor.
 */
module.exports = class Text extends Element {

  /**
   * Create text
   * @param {Layer} layer - Layer to be rendered into
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer, id) {
    super(layer, "text", id);
    this.isText = true;
  }
}
