import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

// import CreativeService from "../../api/CreativeService";
import BuilderMode from "../builderMode";
import CustomGrid from "../commonUI/CustomGrid";

const SharedScreen = () => {
  const [sharedTemplates, setSharedTemplates] = useState([]);
  //   useEffect(() => {
  //     const status = "SHARED";
  //     const type = "BOX";
  //     CreativeService.getCreatives(status, type).then((response) => {
  //       console.log(response);
  //       if (response.status === 200) {
  //         const sharedItems = response.data;
  //         setSharedTemplates(sharedItems);
  //       }
  //     });
  //   }, []);

  const listedItems = sharedTemplates.map((item) => {
    return (
      <Grid item key={item.id} xs={4} sm={4} md={3} xl={2}>
        <BuilderMode
          color="blue"
          title={item.title}
          moduleText={item.template.type}
        />
      </Grid>
    );
  });

  // console.log(sharedTemplates);

  return (
    <div className="shared-screen">
      <CustomGrid>{listedItems}</CustomGrid>
    </div>
  );
};

export default SharedScreen;
