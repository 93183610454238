import React, { useEffect, useState, useContext, useCallback } from "react";
import { Tooltip, Box, Grid } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import LinearProgress from "@mui/material/LinearProgress";

import BuilderMode from "../builderMode";
// import { templatesData } from "../../api/mockData/mockData";
import CommonContext from "../../store/common-context";
import TemplateService from "../../api/TemplateService";
import AuthContext from "../../store/auth-context";
import ShareModal from "./ShareModal";
import CustomGrid from "../commonUI/CustomGrid";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [isShownModal, setIsShownModal] = useState(false);
  const [templateData, setTemplateData] = useState("");
  const [laoding, setLaoding] = useState(false);
  // const [filteredTemplates, setFilteredTemplates] = useState(templatesData);

  const {
    // selectedCategory,
    // selectedFormat,
    // setSelectedFormat,
    // checkedFilters,
    enteredSearch,
  } = useContext(CommonContext);
  const { isLoggedIn } = useContext(AuthContext);

  const getTemplates = useCallback(() => {
    const queryParams = { search: enteredSearch };
    TemplateService.getTemplateList(queryParams).then((response) => {
      setLaoding(false);
      const templateList = response.data.content;
      response.status === 200 && setTemplates(templateList);
    });
  }, [enteredSearch]);

  useEffect(() => {
    setLaoding(true);

    const searchDelay = setTimeout(() => {
      getTemplates();
    }, 500);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [enteredSearch]);

  useEffect(() => {
    getTemplates();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   const filteredList = templatesData.filter((template) => {
  //     let lowerTitle = template.title.toLowerCase();
  //     let lowerSearch = enteredSearch.toLowerCase();

  //     return selectedCategory === "All Categories" &&
  //       checkedFilters.every((searchString) => {
  //         return template.propertyList.includes(searchString);
  //       })
  //       ? lowerTitle.includes(lowerSearch)
  //       : lowerTitle.includes(lowerSearch) &&
  //           selectedCategory === template.category &&
  //           (selectedFormat !== "All Formats"
  //             ? selectedFormat === template.builderType
  //             : true) &&
  //           checkedFilters.every((searchString) => {
  //             return template.propertyList.includes(searchString);
  //           });
  //   });

  //   setFilteredTemplates(filteredList);
  // }, [enteredSearch, selectedCategory, selectedFormat, checkedFilters]);

  // useEffect(() => {
  //   setSelectedFormat("All Formats");
  // }, [selectedCategory]);

  const handleOpenModal = (template) => {
    setIsShownModal((prevState) => !prevState);
    setTemplateData(template);
  };

  const templateList = templates.map((template) => {
    return (
      <Grid item key={template?.id} xs={4} sm={4} md={3} xl={2}>
        <BuilderMode
          title={template?.name}
          color={"blue"}
          moduleText={template?.description}
          cardType="builder"
          templateId={template.id}
          iconSection={
            <Tooltip title="Share" arrow>
              <ShareIcon onClick={() => handleOpenModal(template)} />
            </Tooltip>
          }
        />
      </Grid>
    );
  });

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );

  const templatesContent = <CustomGrid>{templateList}</CustomGrid>;

  return (
    <div className="templates-screen">
      {laoding ? loadingContent : templatesContent}
      {isShownModal && (
        <ShareModal
          templateData={templateData}
          setIsShownModal={setIsShownModal}
          onOpen={isShownModal}
        />
      )}
    </div>
  );
};

export default Templates;
