export const calculateTime = (pastTime) => {
  // Parse the past time string into a Date object
  const pastDate = new Date(pastTime);

  // Get the current time in milliseconds
  const currentTime = Date.now();

  // Calculate the difference in milliseconds
  const diff = currentTime - pastDate.getTime();

  // Convert the difference to minutes, hours, or days
  const diffInMinutes = Math.floor(diff / 60000);
  const diffInHours = Math.floor(diff / 3600000);
  const diffInDays = Math.floor(diff / 86400000);

  // Display the appropriate output
  if (diffInDays > 0) {
    return `Edited: ${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `Edited: ${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else {
    return `Edited: ${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  }
};

export const timeDifference = (lastEdit) => {
  const currentTime = new Date().getTime();
  const lastTime = new Date(lastEdit).getTime();

  const diff = currentTime - lastTime;

  const diffInMinutes = Math.floor(diff / 60000);
  const diffInHours = Math.floor(diff / 3600000);
  const diffInDays = Math.floor(diff / 86400000);

  // Display the appropriate output
  if (diffInDays > 0) {
    return `Edited: ${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `Edited: ${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else {
    return `Edited: ${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  }
};

export const splitToRgba = (rgbaString) => {
  const rgbaValues = rgbaString
    .substring(rgbaString.indexOf("(") + 1, rgbaString.lastIndexOf(")"))
    .split(",")
    .map((value) => parseFloat(value.trim()));

  const [r, g, b, a] = rgbaValues;

  return { r, g, b, a };
};

export const opacityCalculater = (value) => {
  let opacity;

  if (value > 9 && value !== 100) {
    opacity = `0.${value}`;
  } else if (value === 100) {
    opacity = "1";
  } else if (10 > value) {
    opacity = `0.0${value}`;
  }

  return opacity;
};

export const scaleCalculator = (sizes) => {
  let scale;
  if (+sizes.width > 500 || +sizes.height > 500) {
    scale = 0.15;
  } else {
    scale = 0.3;
  }

  return scale;
};

export const extractPxUnit = (value) => {
  // Check if the string contains "px" using a regular expression
  const hasPx = /\d+px/.test(value);

  if (hasPx) {
    // Extract the numeric part using regular expression match
    const numericValue = parseInt(value, 10);

    return numericValue;
  } else {
    return value;
  }
};
