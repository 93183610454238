import React, { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CommonContext from "../../store/common-context";

const Loading = () => {
  const { isLoading, setIsLoading } = useContext(CommonContext);

  const closeLoading = () => {
    setIsLoading(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: "3000" }}
        open={isLoading}
        onClick={closeLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loading;
