import http from "./http-common";

const TEMPLATE_BASE = "/creative-template";

const getTemplateList = (templateParams) => {
  return http.get(TEMPLATE_BASE, {
    params: templateParams,
  });
};

const TemplateService = { getTemplateList };

export default TemplateService;
