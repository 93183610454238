import React, { useState } from "react";
import CommonContext from "./common-context";

const CommonProvider = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShownProjectModal, setIsShownProjectModal] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [isShownBannerModal, setIsShownBannerModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [selectedFormat, setSelectedFormat] = useState("All Formats");
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [hasError, setHasError] = useState({
    open: false,
    message: "",
    vertical: "top",
    horizontal: "center",
  });
  const [isShared, setIsShared] = useState(false);
  const [enteredSearch, setEnteredSearch] = useState("");

  const contextValue = {
    hasError,
    setHasError,
    isLoading,
    setIsLoading,
    userInfo,
    setUserInfo,
    isShownProjectModal,
    setIsShownProjectModal,
    projectTitle,
    setProjectTitle,
    projectDescription,
    setProjectDescription,
    activeProjects,
    setActiveProjects,
    bannerTitle,
    setBannerTitle,
    isShownBannerModal,
    setIsShownBannerModal,
    selectedCategory,
    setSelectedCategory,
    selectedFormat,
    setSelectedFormat,
    checkedFilters,
    setCheckedFilters,
    isShared,
    setIsShared,
    enteredSearch,
    setEnteredSearch,
  };

  return (
    <CommonContext.Provider value={contextValue}>
      {props.children}
    </CommonContext.Provider>
  );
};

export default CommonProvider;
