import React, { Fragment } from "react";
import * as ReactDOM from "react-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { Button, DialogActions, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./Modal.scss";

const ModalPaper = styled(Paper)(({ theme }) => ({
  padding: "0 24px !important",
}));

const ModalOverlay = (props) => {
  const {
    onOpen,
    onHide,
    title,
    children,
    onAccept,
    isDisabled,
    acceptLabel,
    hideCancel,
    hideActions,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      PaperComponent={ModalPaper}
      fullScreen={fullScreen}
      open={onOpen}
      onClose={onHide}
    >
      <section className="modal-title">
        <DialogTitle>{title}</DialogTitle>
        <CloseIcon onClick={onHide} />
      </section>
      <DialogContent
        sx={{
          minWidth: "300px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          px: 0,
          py: 4,
          borderBottom: "1px solid rgba(196,196,196, .3 )",
        }}
      >
        {children}
      </DialogContent>
      {!hideActions && (
        <DialogActions sx={{ px: 0, py: 1.5 }}>
          {!hideCancel && (
            <Button variant="contained" color="error" onClick={onHide}>
              Cancel
            </Button>
          )}

          {acceptLabel && (
            <Button
              variant="contained"
              color="primary"
              onClick={onAccept}
              disabled={isDisabled}
            >
              {acceptLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const portalELement = document.getElementById("modal");

const Modal = (props) => {
  const {
    onOpen,
    onHide,
    title,
    children,
    onAccept,
    isDisabled,
    acceptLabel,
    hideCancel,
    hideActions,
  } = props;
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onOpen={onOpen}
          onHide={onHide}
          title={title}
          onAccept={onAccept}
          isDisabled={isDisabled}
          acceptLabel={acceptLabel}
          hideCancel={hideCancel}
          hideActions={hideActions}
        >
          {children}
        </ModalOverlay>,
        portalELement
      )}
    </Fragment>
  );
};

export default Modal;
