export const fontSizeProps = [];

for (let i = 8; i <= 240; i += 2) {
  const fontSize = `${i}px`;
  fontSizeProps.push({
    value: fontSize,
    label: `${i}`,
  });
}

export const fontTypeProps = [
  {
    value: "Arial",
    label: "Arial",
  },
  {
    value: `"Arial Black"`,
    label: "Arial Black",
  },
  {
    value: `"Times New Roman"`,
    label: "Times New Roman",
  },
  {
    value: `"JetBrains Mono", monospace`,
    label: `JetBrains Mono`,
  },
  {
    value: '"Source Sans Pro"',
    label: "Source Sans Pro",
  },

  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Verdana",
    label: "Verdana",
  },
  {
    value: "Impact",
    label: "Impact",
  },
];

export const borderStyleProps = [
  {
    value: "none",
    label: "none",
  },
  {
    value: "hidden",
    label: "hidden",
  },
  {
    value: "dotted",
    label: "dotted",
  },
  {
    value: "dashed",
    label: "dashed",
  },
  {
    value: "solid",
    label: "solid",
  },
  {
    value: "double",
    label: "double",
  },
  {
    value: "groove",
    label: "groove",
  },
  {
    value: "ridge",
    label: "ridge",
  },
  {
    value: "inset",
    label: "inset",
  },
  {
    value: "outset",
    label: "outset",
  },
  {
    value: "inherit",
    label: "inherit",
  },
];

export const addContents = {
  basics: {
    id: 1,
    name: "Basics",
    basic: [
      { id: 11, name: "Image" },
      { id: 12, name: "Text" },
      { id: 13, name: "Shape" },
    ],
  },

  advanceds: {
    id: 2,
    name: "Advanced",
    advanced: [{ id: 21, name: "Video" }],
  },

  buttons: {
    id: 3,
    name: "Buttons",
    button: [
      { id: 31, name: "Button" },
      // { id: 32, name: "Close Button" },
      // { id: 33, name: "Navigation Button" },
    ],
  },
};

export const animationTypes = [
  {
    value: "bounce",
    label: "Bounce",
  },
  {
    value: "flash",
    label: "Flash",
  },
  {
    value: "pulse",
    label: "Pulse",
  },
];

export const actionTypes = [
  {
    value: "click",
    label: "Click",
  },
];

export const speedProps = [
  {
    value: "slow",
    label: "Slow",
  },
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "fast",
    label: "Fast",
  },
];

export const mockFiles = [
  {
    id: "1",
    fileName: "index.js",
    mode: "javascript",
    contentValue: `function onLoad(editor) {
      console.log("i've loaded index.js");
    }`,
  },
  {
    id: "2",
    fileName: "app.js",
    mode: "javascript",
    contentValue: `function onLoad(editor) {
      console.log("i've loaded app.js");
    }`,
  },
  {
    id: "3",
    fileName: "app.css",
    mode: "css",
    contentValue: `.app {
      margin: 10px;
    }`,
  },
];

export const selectData = [
  { category: "All Categories", formats: [] },
  {
    category: "Display",
    formats: [
      "All Formats",
      "Standart",
      "Polite Zoom",
      "Dimmer",
      "Roll for video",
      "Click to FullScreen",
      "3D Box Leader",
      "3D Box MPU",
      "InView",
    ],
  },
  { category: "Expandable", formats: ["All Formats"] },
  { category: "Takeover", formats: ["All Formats"] },
  { category: "Floating", formats: ["All Formats"] },
];

export const filters = [
  "Quick Templates",
  "Builder Mode Templates",
  "Programmatic Rich Media",
  "Private Templates",
  "Show Empty Templates",
];

export const templatesData = [
  {
    title: "3D Box",
    builderType: "3D Box MPU",
    category: "Display",
    propertyList: ["Quick Templates", "Builder Mode Templates"],
  },
  {
    title: "Pushdown Ad",
    builderType: "IAB Pushdown(Single file)",
    category: "Expandable",
    propertyList: ["Builder Mode Templates"],
  },
  {
    title: "Floating Blank",
    builderType: "Floating",
    category: "Floating",
    propertyList: ["Quick Templates"],
  },
  {
    title: "Slider",
    builderType: "Standart",
    category: "Display",
    propertyList: ["Quick Templates", "Builder Mode Templates"],
  },
  {
    title: "Standart MPU",
    builderType: "Standart",
    category: "Display",
    propertyList: ["Private Templates"],
  },
];
