import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import "./FileInput.scss";

const FileInput = (props) => {
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    props.onFileSelect(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    props.onFileSelect(file);
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop} className="file-input">
      <label htmlFor="fileInput" className="label">
        <UploadFileIcon />
        <p>Click to create new banner or drag and drop file here...</p>
      </label>
      <input
        id="fileInput"
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg"
        onChange={handleFileSelect}
        className="input-element"
      />
    </div>
  );
};

export default FileInput;
