const Element = require("./Element");

/** 
 * Class representing an image element in Ad-Creative Editor.
 */
module.exports = class Image extends Element {

  /**
   * Create image
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer,id) {
    super(layer, "image", id);
    this.isImage = true;
  }

  /**
   * 
   * @param {File} file 
   * @returns {Image} image object
   */
  renderFromFile(file) {
    const reader = new FileReader();
    let thiz = this;
    reader.onload = (e) => {
      var filePayload = e.target.result;
      thiz.renderElement(filePayload);
    };
    reader.readAsDataURL(file);

    return this;
  }

  /**
   * 
   * @param {string} url 
   * @returns {Image} image object
   */
  renderFromUrl(url) {
    if (this.isValidUrl(url)) {
      this.renderElement(url);
      this.url = url;
    }

    return this;
  }

  renderElement(src) {
    document.querySelector("." + this.id).src = src;
  }
}
