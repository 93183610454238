import http from "./http-common";

const PROJECT_BASE = "/project";

const getProjectById = (projectId) => {
  return http.get(PROJECT_BASE + `/${projectId}`);
};

const updateProjectById = (projectId, updatedData) => {
  return http.put(PROJECT_BASE + `/${projectId}`, updatedData);
};

const deleteProjectById = (projectId, deleteCreativesStatus) => {
  return http.delete(PROJECT_BASE + `/${projectId}`, {
    params: {
      deletedCreatives: deleteCreativesStatus,
    },
  });
};

const getProjectsList = (projectParams) => {
  return http.get(PROJECT_BASE, {
    params: projectParams,
  });
};

const createProject = (projectName, projectDescription) => {
  return http.post(PROJECT_BASE, {
    name: projectName,
    description: projectDescription,
  });
};

const getAssignableProjects = () => {
  //This function to get the portable project list when you want to migrate from the project that includes the Creative element.
  return http.get(PROJECT_BASE + "/assign");
};

const ProjectService = {
  getProjectById,
  updateProjectById,
  deleteProjectById,
  getProjectsList,
  createProject,
  getAssignableProjects,
};

export default ProjectService;
