module.exports = class Animation {

    /** 
     * Class representing animation for Ad-Creative Element.
     * @param {string} type 
     * @param {string} delay
     * @param {string} repeat
     * @param {string} duration
     */
    constructor(type, delay, repeat, duration) {
        this.duration = duration;
        this.delay = delay;
        this.repeat = repeat;
        this.type = type;
    }
}