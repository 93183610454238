import { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";

import Modal from "../commonUI/Modal";
import { currentDomain } from "../../configs/config";

const ShareModal = ({ setIsShownModal, onOpen, templateData }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      `${currentDomain}/#/home/create-banner/${templateData?.id}`
    );
    setIsCopied(true);
  };

  const handleClose = () => {
    setIsShownModal((prevState) => !prevState);
  };

  return (
    <Modal onHide={handleClose} onOpen={onOpen} title="Template sharing link">
      <Typography variant="body1" color="text.secondary">
        To share this template simply copy following URL:
      </Typography>
      <TextField
        sx={{ minWidth: "500px" }}
        variant="outlined"
        value={`${currentDomain}/#/home/create-banner/${templateData?.id}`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isCopied ? (
                <span>Copied!</span>
              ) : (
                <IconButton onClick={handleCopyClick} edge="end">
                  <FileCopy />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Modal>
  );
};

export default ShareModal;
