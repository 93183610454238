import React, { useState } from "react";
import AtlasEditor from "atlas-editor";

import TemplateContext from "./template-context";
// import { mockFiles } from "../api/mockData/mockData";

const initialTreeData = {
  name: "Main Asset",
  type: "folder",
  children: [
    { name: "index.html", type: "file" },
    { name: "assets", type: "folder", children: [] },
  ],
};

const TemplateProvider = (props) => {
  const [layers, setLayers] = useState([]);
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);
  const [nodeName, setNodeName] = useState("");
  const [treeData, setTreeData] = useState(initialTreeData);
  const [isShownAce, setIsShownAce] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [selectedFile, setSelectedFile] = useState("0");
  const [indexHTML, setIndexHTML] = useState("");
  const [isShownSave, setIsShownSave] = useState(false);
  const [viewportSizes, setViewportSizes] = useState({
    width: "300",
    height: "300",
  });
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [bannerData, setBannerData] = useState(null);
  const [selectedElement, setSelectedElement] = useState({});

  const atlasEditor = new AtlasEditor();

  const triggerSaveButton = () => {
    !isShownSave && setIsShownSave(true);
  };

  const templateContext = {
    layers,
    setLayers,
    selectedLayerIndex,
    setSelectedLayerIndex,
    atlasEditor,
    nodeName,
    setNodeName,
    treeData,
    setTreeData,
    isShownAce,
    setIsShownAce,
    fileData,
    setFileData,
    selectedFile,
    setSelectedFile,
    indexHTML,
    setIndexHTML,
    isShownSave,
    setIsShownSave,
    viewportSizes,
    setViewportSizes,
    isPreviewMode,
    setIsPreviewMode,
    bannerData,
    setBannerData,
    selectedElement,
    setSelectedElement,
    triggerSaveButton,
  };

  return (
    <TemplateContext.Provider value={templateContext}>
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateProvider;
