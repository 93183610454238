import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Grid, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import ProjectService from "../../api/ProjectService";
import { timeDifference } from "../../api/UtilsService";
import CommonContext from "../../store/common-context";
import ProjectModal from "../commonUI/ProjectModal";
import logo from "../../assets/body/Bg3.jpg";
import "./ActiveProjects.scss";
import ProjectCard from "../commonUI/ProjectCard";
import ProjectDeleteModal from "../commonUI/ProjectDeleteModal";
import CreativeService from "../../api/CreativeService";
import ButtonPagination from "../commonUI/ButtonPagination";
import { menuListConfig } from "../../configs/config";
import CustomGrid from "../commonUI/CustomGrid";

const ActiveProjects = () => {
  const [editMod, setEditMod] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isShownDeleteModal, setIsShownDeleteModal] = useState(false);
  const [sandboxBannersCount, setSandboxBannersCount] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [laoding, setLaoding] = useState(false);

  const {
    setIsLoading,
    setIsShownProjectModal,
    setProjectTitle,
    setProjectDescription,
    isShownProjectModal,
    projectTitle,
    projectDescription,
    activeProjects,
    setActiveProjects,
    enteredSearch,
  } = useContext(CommonContext);

  useEffect(() => {
    setPage(0);
  }, [enteredSearch]);

  const queryParams = {
    search: enteredSearch,
    statusList: menuListConfig.activeStatus,
    sortBy: menuListConfig.projectSort,
    sortDirection: menuListConfig.listDirection,
    size: menuListConfig.itemCount,
    page: page,
  };

  const getActiveProjects = useCallback(() => {
    ProjectService.getProjectsList(queryParams).then((response) => {
      setLaoding(false);
      setTotalPage(response.data.totalPages);
      const activeList = response?.data?.content;
      response.status === 200 && setActiveProjects(activeList);
    });
  }, [enteredSearch, page]);

  useEffect(() => {
    setLaoding(true);

    const searchDelay = setTimeout(() => {
      getActiveProjects();
    }, 500);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [getActiveProjects, enteredSearch, page]);

  useEffect(() => {
    const queryParams = { statusList: menuListConfig.activeStatus };
    CreativeService.getCreatives(queryParams).then((response) => {
      if (response.status === 200) {
        const sandboxCount = response.data.totalItems;
        setSandboxBannersCount(sandboxCount);
      }
    });
  }, []);

  const handleOpenDeleteModal = (cardIndex) => {
    setIsShownDeleteModal(true);
    const selectedItem = activeProjects[cardIndex];
    setProjectTitle(activeProjects[cardIndex].name);
    setSelectedProject(selectedItem);
  };

  const handleDelete = (creativeStatus) => {
    setIsLoading(true);
    ProjectService.deleteProjectById(selectedProject.id, creativeStatus).then(
      (response) => {
        response.status === 200 && getActiveProjects();
        setIsLoading(false);
      }
    );
  };

  const handleMoveToArchive = (cardIndex) => {
    setIsLoading(true);
    const selectedItem = activeProjects[cardIndex];
    const updatedObject = {
      ...selectedItem,
      status: menuListConfig.archivedStatus,
    };

    ProjectService.updateProjectById(selectedItem.id, updatedObject).then(
      (response) => {
        response.status === 200 && getActiveProjects();
        setIsLoading(false);
      }
    );
  };

  const handleClickEdit = (cardIndex) => {
    const selectedItem = activeProjects[cardIndex];
    setSelectedProject(selectedItem);
    setEditMod(true);
    setIsShownProjectModal(true);
    setProjectTitle(activeProjects[cardIndex]?.name);
    setProjectDescription(activeProjects[cardIndex]?.description);
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    const updatedObject = {
      ...selectedProject,
      name: projectTitle,
      description: projectDescription,
    };

    ProjectService.updateProjectById(selectedProject.id, updatedObject).then(
      (response) => {
        if (response.status === 200) {
          getActiveProjects();
          setIsShownProjectModal(false);
          setEditMod(false);
          setProjectTitle("");
          setProjectDescription("");
          setIsLoading(false);
        }
      }
    );
  };

  const listedItems = activeProjects.map((project, index) => {
    const timeDiff = timeDifference(project.updatedOn);

    let creatives = project.creatives.filter(
      (creative) => creative.status === menuListConfig.activeStatus
    );

    return (
      <Grid key={project.id} item xs={4} sm={4} md={3} xl={2}>
        <ProjectCard
          image={logo}
          project={project}
          creativeCount={
            <>
              <FilterNoneOutlinedIcon />
              <div>{creatives.length}</div>
            </>
          }
          editLabel={timeDiff}
          iconSection={
            <>
              <Tooltip title="Edit" arrow>
                <EditIcon
                  onClick={() => handleClickEdit(index)}
                  className="icon-button"
                />
              </Tooltip>
              <Tooltip title="Archive Project" arrow>
                <ArchiveIcon
                  onClick={() => handleMoveToArchive(index)}
                  className="icon-button"
                />
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <DeleteIcon
                  onClick={() => handleOpenDeleteModal(index)}
                  className="icon-button"
                />
              </Tooltip>
            </>
          }
        />
      </Grid>
    );
  });

  const sandboxProject = {
    name: "Sandbox",
    description: "Sandbox is used for quick banner testing and authoring",
  };

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );

  const projectList = (
    <>
      <CustomGrid>
        <Grid item xs={4} sm={4} md={3} xl={2}>
          <Link to="/home/sandbox">
            <ProjectCard
              image={logo}
              project={sandboxProject}
              editLabel="Banners"
              creativeCount={
                <>
                  <FilterNoneOutlinedIcon />
                  <div>{sandboxBannersCount}</div>
                </>
              }
            />
          </Link>
        </Grid>

        {listedItems}
      </CustomGrid>

      <ButtonPagination
        page={page + 1}
        setPage={setPage}
        totalPage={totalPage}
      />
    </>
  );

  return (
    <>
      <div className="active-screen">
        {laoding ? loadingContent : projectList}
      </div>

      {isShownProjectModal && (
        <ProjectModal editMod={editMod} handleSaveChanges={handleSaveChanges} />
      )}

      {isShownDeleteModal && (
        <ProjectDeleteModal
          onOpen={isShownDeleteModal}
          onHide={() => setIsShownDeleteModal(false)}
          modalTitle={`Delete Project: "${projectTitle}"`}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default ActiveProjects;
