import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LazyLoading = () => {
  return (
    <div
      className="loading"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
    >
      <Box sx={{ display: "flex", width: "5rem", height: "5rem" }}>
        <CircularProgress style={{ width: "5rem", height: "5rem" }} />
      </Box>
    </div>
  );
};

export default LazyLoading;
