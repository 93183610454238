import React, { useState, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { LinearProgress, Typography } from "@mui/material";

import CreativeService from "../../api/CreativeService";
import AdSizeModal from "../templates/AdSizeModal";
import TemplateContext from "../../store/template-context";
import MiniPreviewMode from "../miniPreviewMode/MiniPreviewMode";

const BuilderMode = ({
  title,
  color,
  moduleText,
  iconSection,
  cardType,
  templateId,
  bannerId,
}) => {
  const [moduleOpen, setModuleOpen] = useState(false);
  const [isShownModal, setIsShownModal] = useState(false);
  const [bannerHTML, setBannerHTML] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canvasSizes, setCanvasSizes] = useState({});

  const editorNode = useRef();

  const { viewportSizes, atlasEditor } = useContext(TemplateContext);

  const location = useLocation();

  const history = useHistory();

  const createBanner = () => {
    const url = location.pathname;
    const regex = /\/projects\/(\d+)\/create-banner/;
    const inProject = url.match(regex);

    const idPart = inProject && inProject[1];

    const projectIdentity = inProject ? idPart : null;
    const templateIdentity = templateId;
    const projectId = projectIdentity;

    const creativeData = projectId
      ? {
          template: {
            id: templateIdentity,
          },
          project: {
            id: projectId,
          },
          adSize: {
            id: viewportSizes.adSize,
            dimensions: viewportSizes.adSizeSize,
          },
        }
      : {
          template: {
            id: templateIdentity,
          },
          adSize: {
            id: viewportSizes.adSize,
            dimensions: viewportSizes.adSizeSize,
          },
        };

    CreativeService.createCreative(creativeData).then((response) => {
      if (response.status === 200) {
        history.push(`/template/${response.data.id}`);
      }
    });
  };

  const clickBuilderMode = () => {
    if (cardType === "builder") {
      setIsShownModal((prevState) => !prevState);
    } else {
      history.push(`/template/${bannerId}`);
    }
  };

  const handleOpenPreview = () => {
    if (cardType !== "builder" && !moduleOpen) {
      setIsLoading((prevState) => !prevState);
      CreativeService.getCreativeById(bannerId).then((response) => {
        if (response.status === 200) {
          /* console.log(response.data); */

          let bannerData = response.data;

          setCanvasSizes({
            ...bannerData?.editorObject?.options?.canvasOptions,
          });

          const updatedDataArray =
            bannerData.editorObject.options.menu.simple.map((item) => {
              if (item.id === "autoplay" || item.id === "showButtons") {
                return { ...item, value: false };
              }
              return item;
            });

          const updatedObject = {
            ...bannerData,
            editorObject: {
              ...bannerData.editorObject,
              options: {
                ...bannerData.editorObject.options,
                menu: {
                  ...bannerData.editorObject.options.menu,
                  simple: updatedDataArray,
                },
              },
            },
          };

          /* console.log({ bannerData }); */

          atlasEditor.renderEditor(
            editorNode.current,
            updatedObject.editorObject
          );

          const htmlOutput = atlasEditor.exportService.generateHTML();

          setBannerHTML(htmlOutput);
          setIsLoading((prevState) => !prevState);
        }
      });
    }

    setModuleOpen((prevState) => !prevState);
  };

  const buttonClass = moduleOpen ? "button-active" : "button-deactive";
  const boardClass = moduleOpen ? "board-active" : "board-deactive";
  const bottomClass = moduleOpen ? "bottom-active" : "bottom-deactive";

  const bannerDetailContent = isLoading ? (
    <LinearProgress sx={{ width: "100%" }} />
  ) : (
    <MiniPreviewMode
      bannerHTML={bannerHTML}
      canvasSizes={canvasSizes}
    />
  );

  return (
    <>
      <div className="modul-container">
        <div className="modul-box">
          <div onClick={clickBuilderMode} className="modul-header">
            <Typography
              fontWeight="medium"
              variant="body2_medium"
              color="white"
            >
              {title}
            </Typography>
            {cardType === "builder" && (
              <span className="badge">
                <Typography
                  fontWeight="medium"
                  variant="body2_medium"
                  color="white"
                >
                  Builder
                </Typography>
              </span>
            )}
          </div>
        </div>

        <button
          className={`button ${buttonClass}`}
          onClick={() => {
            handleOpenPreview();
          }}
        >
          {moduleOpen ? <FaAngleDown /> : <FaAngleUp />}
        </button>

        <div className="bottom-wrapper">
          <div className={boardClass}>
            <div className="white-paper">
              <div style={{ display: "none" }} ref={editorNode} />
              {moduleOpen && bannerDetailContent}
            </div>
          </div>
          <div className={`bottom ${bottomClass}`}>
            <p>{moduleText}</p>
            <div className="icon-box">{iconSection}</div>
          </div>
        </div>
      </div>

      {isShownModal && (
        <AdSizeModal
          onOpen={isShownModal}
          onHide={() => setIsShownModal((prevState) => !prevState)}
          onCreate={createBanner}
          templateName={title}
        />
      )}
    </>
  );
};

export default BuilderMode;
