import React, { useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import CommonContext from "../../store/common-context";
import { selectData } from "../../api/mockData/mockData";
import { filters } from "../../api/mockData/mockData";
import Searchbar from "../commonUI/Searchbar";

const Navbar = () => {
  // const [isChecked, setIsChecked] = useState(false);

  const {
    setIsShownProjectModal,
    userInfo,
    // selectedCategory,
    // setSelectedCategory,
    // selectedFormat,
    // setSelectedFormat,
    // checkedFilters,
    // setCheckedFilters,
  } = useContext(CommonContext);

  const history = useHistory();

  const location = useLocation();

  let navContents = {
    title: null,
    body: null,
  };

  // let formatContents = selectData.filter(
  //   (item) => item.category === selectedCategory
  // );

  // const handleChangeCheckbox = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  // const handleChangeFilter = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCheckedFilters(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleChangeCategory = (event) => {
  //   setSelectedCategory(event.target.value);
  // };

  // const handleChangeFormat = (event) => {
  //   setSelectedFormat(event.target.value);
  // };

  const projects = location.pathname.includes("/home/projects");

  if (projects) {
    navContents.title = "Active Projects";
    navContents.body = (
      <>
        <Button
          size="small"
          edge="start"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => history.push(`${location.pathname}/create-banner`)}
        >
          New Banner
        </Button>

        {/* <FormControlLabel
      value="end"
      control={<Checkbox />}
      label="Select All"
      labelPlacement="end"
      checked={isChecked}
      onChange={handleChangeCheckedbox}
    /> */}
      </>
    );
  }

  if (location.pathname === "/home/active") {
    navContents.title = "Active Projects";
    navContents.body = (
      <Button
        size="small"
        edge="start"
        sx={{ minWidth: "9rem" }}
        variant="contained"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setIsShownProjectModal(true)}
      >
        New Project
      </Button>
    );
  }

  if (location.pathname === "/home/sandbox") {
    navContents.title = "Sandbox";
    navContents.body = (
      <>
        <Button
          size="small"
          edge="start"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => history.push("/home/templates")}
        >
          New Banner
        </Button>

        {/* <FormControlLabel
      value="end"
      control={<Checkbox />}
      label="Select All"
      labelPlacement="end"
      checked={isChecked}
      onChange={handleChangeCheckedbox}
    /> */}
      </>
    );
  }

  if (location.pathname === "/home/archived") {
    navContents.title = "Archived";
    navContents.body = null;
  }

  if (location.pathname === "/home/shared") {
    navContents.title = "Shared";
    navContents.body = null;
  }

  if (location.pathname === "/home/templates") {
    navContents.title = "Templates";
    navContents.body = (
      <Box
        sx={{
          m: 1,
          minWidth: 240,
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
      >
        {/* <FormControl variant="standard">
          <Select
            value={selectedCategory}
            label="Categories"
            onChange={handleChangeCategory}
            sx={{ color: "white" }}
          >
            {selectData.map((item) => {
              const category = item.category;
              return (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedCategory !== "All Categories" && (
          <FormControl variant="standard">
            <Select
              value={selectedFormat}
              label="Formats"
              onChange={handleChangeFormat}
              sx={{ color: "white" }}
            >
              {formatContents[0].formats.map((format) => {
                return (
                  <MenuItem key={format} value={format}>
                    {format}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <FormControl
          sx={{ maxWidth: "15rem", height: "2rem" }}
          vvariant="outlined"
        >
          <InputLabel sx={{ color: "#fff", top: "-2rem" }}>Filter</InputLabel>
          <Select
            sx={{
              maxWidth: "15rem",
              minWidth: "10rem",
              height: "2rem",
              color: "#fff",
            }}
            variant="outlined"
            multiple
            value={checkedFilters}
            onChange={handleChangeFilter}
            input={<OutlinedInput label="Filter" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {filters.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={checkedFilters.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Box>
    );
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "rgba(39, 39, 39, 0)", boxShadow: "none" }}
    >
      <Toolbar
        sx={{ gap: "2rem", padding: "0 32px !important", height: "5rem" }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", minWidth: "200px" }}
        >
          <Typography variant="h8" color={"white"}>
            Ad Creative Builder
          </Typography>
          <Typography variant="h6" color={"white"}>
            {navContents.title}
          </Typography>
        </Box>

        {navContents.body && <Box>{navContents.body}</Box>}
        <Searchbar />
        <div className="user-info">
          {userInfo?.name ? (
            <Avatar sx={{ backgroundColor: "#33a7fb" }}>
              {userInfo?.name?.charAt([0])}
            </Avatar>
          ) : (
            <Avatar sx={{ backgroundColor: "#33a7fb" }}>N</Avatar>
          )}

          {userInfo?.name ? (
            <h1>
              {userInfo?.name} {userInfo?.familyName}
            </h1>
          ) : (
            <h1>Null Username</h1>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
