import http from "./http-common";

const TOKEN_BASE = `/user/auth`;

const getToken = (code, redirect_uri) => {
  return http.post(
    TOKEN_BASE,
    {
      // data to sent to the server - post body
    },
    {
      params: {
        code: code,
        redirect_uri: redirect_uri,
      },
    }
  );
};

const getUserInfo = () => {
  return http.get("/user");
};

const fakeLogin = () => {
  return http.post(
    "/user/login",
    {},
    {
      params: {
        phone: "1234",
      },
    }
  );
};

const AuthService = { getToken, getUserInfo, fakeLogin };

export default AuthService;
