const Element = require("./Element");
const Layer = require("./Layer").prototype;

/**
 * Class representing a shape element in Ad-Creative Editor.
 */
module.exports = class Shape extends Element {
  /**
   * Create shape
   * @param {Layer} layer - Layer to be rendered into
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer, id) {
    super(layer, "shape", id);
    this.isShape = true;
  }
};
