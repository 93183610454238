import React from "react";

const AuthContext = React.createContext({
  token: "",
  setToken: () => {},
  isLoggedIn: false,
  loginHandler: (token) => {},
  logoutHandler: () => {},
});

export default AuthContext;
