import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import CreativeService from "../../api/CreativeService";
import BuilderMode from "../builderMode";
import { calculateTime } from "../../api/UtilsService";
import CommonContext from "../../store/common-context";
import BannerTitleEdit from "../commonUI/BannerTitleEdit";
import FileInput from "../commonUI/FileInput";
// import TemplateContext from "../../store/template-context";
import ButtonPagination from "../commonUI/ButtonPagination";
import { menuListConfig } from "../../configs/config";
import CustomGrid from "../commonUI/CustomGrid";

const SandboxScreen = () => {
  const [sandboxBanners, setSandboxBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [laoding, setLaoding] = useState(false);

  const {
    isShownBannerModal,
    setIsShownBannerModal,
    setBannerTitle,
    bannerTitle,
    setIsLoading,
    enteredSearch,
  } = useContext(CommonContext);

  // const { layers, setLayers, atlasEditor } = useContext(TemplateContext);

  const history = useHistory();

  useEffect(() => {
    setPage(0);
  }, [enteredSearch]);

  const queryParams = {
    search: enteredSearch,
    statusList: menuListConfig.activeStatus,
    sortBy: menuListConfig.bannerSort,
    sortDirection: menuListConfig.listDirection,
    size: menuListConfig.itemCount,
    page: page,
  };

  const getSandboxBanners = useCallback(() => {
    CreativeService.getCreatives(queryParams).then((response) => {
      if (response.status === 200) {
        setLaoding(false);
        setTotalPage(response.data.totalPages);
        const sandboxItems = response.data.content;

        setSandboxBanners([...sandboxItems]);
      }
    });
  }, [enteredSearch, page]);

  useEffect(() => {
    setLaoding(true);

    const searchDelay = setTimeout(() => {
      getSandboxBanners();
    }, 500);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [getSandboxBanners, enteredSearch, page]);

  const handleClickEdit = (bannerIndex) => {
    setIsShownBannerModal(true);
    const selectedCreative = sandboxBanners[bannerIndex];
    setSelectedBanner(selectedCreative);
    setBannerTitle(sandboxBanners[bannerIndex]?.title);
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    const updatedObject = { ...selectedBanner, title: bannerTitle };

    CreativeService.updateCreativeById(selectedBanner?.id, updatedObject).then(
      (response) => {
        if (response.status === 200) {
          getSandboxBanners();
          setIsShownBannerModal(false);
          setBannerTitle("");
          setIsLoading(false);
        }
      }
    );
  };

  const handleDelete = (bannerIndex) => {
    setIsLoading(true);
    const creativeId = sandboxBanners[bannerIndex]?.id;
    CreativeService.deleteCreative(creativeId).then((response) => {
      response.status === 200 && getSandboxBanners();
    });

    setIsLoading(false);
  };

  const handleFileSelect = (file) => {
    setIsLoading(true);
    let isStandard = file.type.includes("image", "jpeg");

    const templateId = 6;

    const creativeData = {
      template: {
        id: templateId,
      },
    };

    isStandard &&
      CreativeService.createCreative(creativeData).then((response) => {
        response.status === 200 &&
          history.push(`/template/${response.data.id}`);
      });

    // atlasEditor.services.ImageService.addImage(
    //   file,
    //   atlasEditor.services.LayerService.layers[0]
    // );

    // layers[0] = atlasEditor.services.LayerService.layers[0];

    // setTimeout(() => {
    //   setLayers([...layers]);
    // }, 50);

    setIsLoading(false);
  };

  const handleClickDownload = (bannerIndex) => {
    const creativeId = sandboxBanners[bannerIndex]?.id;
    const isCompressed = false;
    CreativeService.downloadCreative(creativeId, isCompressed).then(
      (response) => {
        response.status === 200 && window.open(response.data);
      }
    );
  };

  const listedItems = sandboxBanners.map((banner, index) => {
    const timeDiff = calculateTime(banner.lastEdit);
    return (
      <Grid item key={banner.id} xs={4} sm={4} md={3} xl={2}>
        <BuilderMode
          color="blue"
          bannerId={banner.id}
          title={banner.title}
          moduleText={timeDiff}
          iconSection={
            <>
              <Tooltip title="Edit Title" arrow>
                <EditIcon onClick={() => handleClickEdit(index)} />
              </Tooltip>
              <Tooltip title="Download" arrow>
                <FileDownloadIcon onClick={() => handleClickDownload(index)} />
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <DeleteIcon onClick={() => handleDelete(index)} />
              </Tooltip>
            </>
          }
        />
      </Grid>
    );
  });

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );

  const bannerList = (
    <>
      <CustomGrid>
        <Grid item xs={4} sm={4} md={3} xl={2}>
          <FileInput onFileSelect={handleFileSelect} />
        </Grid>
        {listedItems}
      </CustomGrid>

      <ButtonPagination
        page={page + 1}
        setPage={setPage}
        totalPage={totalPage}
      />
    </>
  );

  return (
    <>
      <div className="sandbox-screen">
        {laoding ? loadingContent : bannerList}
      </div>
      {isShownBannerModal && (
        <BannerTitleEdit handleSaveChanges={handleSaveChanges} />
      )}
    </>
  );
};

export default SandboxScreen;
