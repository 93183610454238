const ElementService = require("./services/ElementService");
const ExportService = require("./services/ExportService");

const MoveableHelper = require("moveable-helper").default;
const keycon = require("keycon").default;
const Guides = require("@scena/guides").default;
const InfiniteViewer = require("infinite-viewer").default;
const Moveable = require("moveable").default;
const Selecto = require("selecto").default;

const $ = require("jquery");
const Mustache = require("mustache");
const MUSTACHE_TEMPLATES = require("./consts/ConstTemplates");

const Layer = require("./models/Layer");
const Animation = require("./models/Animation");
const EditorObject = require("./models/editor/EditorObject");

const EventEmitter = require("events");

resetGlobalApp = function () {
  global._app = new EditorObject();
};
resetGlobalApp();

module.exports = class Editor extends EventEmitter {
  constructor() {
    super();
    this.elementService = new ElementService();
    this.exportService = new ExportService();
  }

  initScenaEditor() {
    let targets = [];
    const moveable = new Moveable(this.editorRef.querySelector("#viewport"), {
      target: [],
      draggable: true,
      scalable: true,
      rotatable: true,
      snappable: true,
      snapCenter: true,
      container: this.editorRef.querySelector("#viewport"),
      edge: true,
      keepRatio: true,
      //isDisplayInnerSnapDigit: true,
      //edgeDraggable: true,
      //snapDigit: 5,
      //originDraggable: true,
      resizable: true,
    });
    this.moveable = moveable;
    global.moveable = moveable;

    const helper = MoveableHelper.create();
    moveable
      .on("dragStart", helper.onDragStart)
      .on("drag", helper.onDrag)
      .on("dragGroupStart", helper.onDragGroupStart)
      .on("dragGroup", helper.onDragGroup)
      .on("scaleStart", helper.onScaleStart)
      .on("scale", helper.onScale)
      .on("scaleGroupStart", helper.onScaleGroupStart)
      .on("scaleGroup", helper.onScaleGroup)
      .on("rotateStart", helper.onRotateStart)
      .on("rotate", helper.onRotate)
      .on("rotateGroupStart", helper.onRotateGroupStart)
      .on("rotateGroup", helper.onRotateGroup)
      .on("resizeStart", helper.onResizeStart)
      .on("resize", helper.onResize)
      .on("resizeGroup", helper.onResizeGroup)
      .on("resizeGroupStart", helper.onResizeGroupStart);
    //.on("dragOrigin", helper.onDragOrigin)
    //.on("dragOriginStart", helper.onDragOriginStart)

    const horizontalGuides = new Guides(
      this.editorRef.querySelector(".guides.horizontal"),
      {
        type: "horizontal",
        snapThreshold: 5,
        snaps: [0, 300, 600],
        displayDragPos: true,
        dragPosFormat: (v) => `${v}px`,
        showGuides: true,
        lineColor: "orange",
        font: "15px sans-serif",
        longLineSize: 7,
        shortLineSize: 10,
        segment: 5,
      }
    ).on("changeGuides", ({ guides }) => {
      moveable.horizontalGuidelines = guides;
    });
    const verticalGuides = new Guides(
      this.editorRef.querySelector(".guides.vertical"),
      {
        type: "vertical",
        snapThreshold: 5,
        snaps: [0, 200, 400],
        displayDragPos: true,
        dragPosFormat: (v) => `${v}px`,
        lineColor: "orange",
        showGuides: true,
        font: "15px sans-serif",
        longLineSize: 7,
        shortLineSize: 10,
        segment: 5,
      }
    ).on("changeGuides", ({ guides }) => {
      moveable.verticalGuidelines = guides;
    });

    const viewer = new InfiniteViewer(
      this.editorRef.querySelector(".viewer"),
      this.editorRef.querySelector("#viewport"),
      {
        usePinch: true,
        pinchThreshold: 1,
        zoomRange: [0.001, 100],
        maxPinchWheel: 20,
        rangeX: [-1500, -500],
        rangeY: [-1500, 500],
        // useAutoZoom: true,
        // threshold: 20,
        // useMouseDrag: true,
      }
    )
      .on("dragStart", (e) => {
        const target = e.inputEvent.target;
        if (
          target.nodeName === "A" ||
          moveable.isMoveableElement(target) ||
          targets.some((t) => t === target || t.contains(target))
        ) {
          e.stop();
        }
      })
      .on("dragEnd", (e) => {
        if (!e.isDrag) {
          selecto.clickTarget(e.inputEvent);
        }
      })
      .on("abortPinch", (e) => {
        selecto.triggerDragStart(e.inputEvent);
      })
      .on("scroll", (e) => {
        horizontalGuides.scroll(e.scrollLeft);
        horizontalGuides.scrollGuides(e.scrollTop);

        verticalGuides.scroll(e.scrollTop);
        verticalGuides.scrollGuides(e.scrollLeft);

        const thumbWidth = 0;
      })
      .on("pinch", (e) => {
        const zoom = e.zoom;
        verticalGuides.zoom = zoom;
        horizontalGuides.zoom = zoom;
        viewer.zoom = zoom;
      });

    const selecto = new Selecto({
      container: this.editorRef.querySelector(".viewer"),
      rootContainer: this.editorRef.querySelector(".viewer"),
      dragContainer: ".viewer",
      hitRate: 0,

      selectableTargets: ["[data-moveable]"],
      toggleContinueSelect: ["shift"],
      scrollOptions: {
        container: viewer.getContainer(),
        useScroll: true,
        threshold: 30,
        throttleTime: 30,
        getScrollPosition: ({ direction }) => {
          return [viewer.getScrollLeft(), viewer.getScrollTop()];
        },
      },
    })
      .on("dragStart", (e) => {
        if (!document.hasFocus()) {
          e.stop();
        }
        const inputEvent = e.inputEvent;
        const target = inputEvent.target;
        if (
          target.nodeName === "A" ||
          inputEvent.type === "touchstart" ||
          moveable.isMoveableElement(target) ||
          targets.some((t) => t === target || t.contains(target))
        ) {
          e.stop();
        }
      })
      .on("scroll", ({ direction }) => {
        viewer.scrollBy(direction[0] * 10, direction[1] * 10);
      })
      .on("selectEnd", (e) => {
        targets = e.selected;
        moveable.target = targets;

        if (e.isDragStart) {
          e.inputEvent.preventDefault();

          setTimeout(() => {
            moveable.dragStart(e.inputEvent);
          });
        }
      });

    requestAnimationFrame(() => {
      viewer.scrollCenter();
    });

    this.editorRef.addEventListener("resize", () => {
      horizontalGuides.resize();
      verticalGuides.resize();
    });

    keycon.global
      .keydown(["shift"], (e) => {
        moveable.throttleDragRotate = 45;
        moveable.throttleRotate = 30;
      })
      .keyup(["shift"], (e) => {
        moveable.throttleDragRotate = 0;
        moveable.throttleRotate = 0;
      });
    this.editorRef.addEventListener(
      "wheel",
      (e) => {
        if (keycon.global.altKey) {
          const zoom = Math.max(0.1, viewer.zoom + e.deltaY / 300);
          e.preventDefault();
          viewer.zoom = zoom;
          verticalGuides.zoom = zoom;
          horizontalGuides.zoom = zoom;
        }
      },
      {
        passive: false,
      }
    );

    this.editorRef.querySelector(".reset").addEventListener("click", () => {
      viewer.scrollCenter();
    });
  }

  /**
   *
   * @param {HTMLDivElement} editorRef
   * @param {EditorObject} editorObject
   */
  renderEditor(editorRef, editorObject) {
    resetGlobalApp();
    global._app = editorObject;

    this.editorRef = editorRef;

    let rendered = Mustache.render(MUSTACHE_TEMPLATES.editor_template);
    $(editorRef).append(rendered);

    this.updateEditorDimentions(
      editorObject.options.canvasOptions.width,
      editorObject.options.canvasOptions.height
    );
    this.initScenaEditor();

    let layers = JSON.parse(JSON.stringify(editorObject.layers));
    global._app.layers = [];

    this.drawElements(layers);
    this.addObserver();
  }

  addObserver() {
    // Options for the observer (which mutations to observe)
    var config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    /**
     *
     * @param {MutationRecord[]} mutationList
     * @param {MutationObserver} observer
     */
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "attributes") {
          if (mutation.attributeName === "style") {
            if (mutation.target.hasAttribute("data-element")) {
              this.emit("elementChange");
            }
            if (mutation.target.classList.contains("moveable-control-box")) {
              if (mutation.target.style.display == "block") {
                var elementClassName = this.moveable.target[0].className;
                this.getApp().layers.forEach((layer) => {
                  if (layer.isVisible()) {
                    layer.getElements().forEach((element) => {
                      if (element.id === elementClassName) {
                        this.emit("elementSelect", element);
                      }
                    });
                  }
                });
              }
            }
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    var observer = new MutationObserver(callback);
    observer.observe(document.getElementById("viewport"), config);
  }

  /**
   *
   * @param {string} width
   * @param {string} height
   */
  updateEditorDimentions(width, height) {
    let canvasOptions = this.getApp().options.canvasOptions;
    let $viewportEl = $("#viewport");

    width = width.toString();
    height = height.toString();

    if (width) {
      if (width.includes("px")) {
        width = width.replace("px", "");
      }
      canvasOptions.width = width;
      $viewportEl.css("width", width + "px");
    }
    if (height) {
      if (height.includes("px")) {
        height = height.replace("px", "");
      }
      canvasOptions.height = height;
      $viewportEl.css("height", height + "px");
    }
  }

  /**
   *
   * @param {Layer[]} layers
   */
  drawElements(layers) {
    layers.forEach((layer) => {
      layer = Object.assign(new Layer(layer.id), layer);

      let elements = JSON.parse(JSON.stringify(layer.elements));
      layer.elements = [];

      var element;
      while ((element = elements.shift())) {
        let editorElement;
        if (element.type == "button") {
          editorElement = this.elementService.addButton(layer, element.id);
          editorElement.setText(element.text);
        } else if (element.type == "image") {
          editorElement = this.elementService.addImageFromUrl(
            layer,
            element.url,
            element.id
          );
        } else if (element.type == "video") {
          editorElement = this.elementService.addVideoFromUrl(
            layer,
            element.url,
            element.id
          );
          editorElement.settings = element.settings;
        } else if (element.type == "shape") {
          editorElement = this.elementService.addShape(layer, element.id);
        } else if (element.type == "text") {
          editorElement = this.elementService.addText(layer, element.id);
          editorElement.setText(element.text);
        }
        editorElement.setStyle(element.style);
        editorElement.setName(element.name);
        editorElement.setClickTagUrl(element.clickTagUrl);
        editorElement.setKeepRatio(element.keepRatio);
        editorElement.setAspectRatio(element.aspectRatio);

        element.animations.forEach((animation) => {
          editorElement.animations.push(
            Object.assign(new Animation(), animation)
          );
        });
      }
    });
  }

  /**
   *
   * @returns {EditorObject}
   */
  getApp() {
    return global._app;
  }

  getOptions() {
    return this.getApp().options;
  }
};
