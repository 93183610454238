import React from "react";

const TemplateContext = React.createContext({
  layers: [],
  setLayers: () => {},
  selectedLayerIndex: 0,
  setSelectedLayerIndex: () => {},
  atlasEditor: {},
  nodeName: "",
  setNodeName: () => {},
  treeData: {},
  setTreeData: () => {},
  isShownAce: false,
  setIsShownAce: () => {},
  fileData: [],
  setFileData: () => {},
  selectedFile: "",
  setSelectedFile: () => {},
  indexHTML: "",
  setIndexHTML: () => {},
  isShownSave: false,
  setIsShownSave: () => {},
  viewportSizes: {},
  setViewportSizes: () => {},
  isPreviewMode: false,
  setIsPreviewMode: () => {},
  bannerData: null,
  setBannerData: () => {},
  selectedElement: {},
  setSelectedElement: () => {},
  triggerSaveButton: () => {},
});

export default TemplateContext;
