module.exports = ELEMENT_STYLES = {
    shape: {
        "background-color": "rgb(175, 175, 175)",
        "position": "absolute",
        "opacity": "0.5",
        "zIndex": "1"
    },
    text: {
        "display": "block",
        "resize": "none",
        "border": "none",
        "outline": "none",
        "overflow": "hidden",
        "overflow-wrap": "normal",
        "white-space": "normal",
        "zIndex": "1",
        "opacity": "0.95",
        "color": "rgb(1, 1, 1)",
        "background-color": "rgba(255, 255, 255, 0)",
        "font-family": "'Source Sans Pro'",
        "font-size": "14px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        "text-align": "left",
        "line-height": "100%",
        "letter-spacing": "0px",
        "text-shadow": "rgba(214, 214, 214, 0) 0px 0px 0px",
        "width": "135px",
        "height": "25px",
        "position": "absolute"
    },
    image: {
        "position": "absolute",
        "opacity": "0.95",
        "zIndex": "1",
    },
    video: {
        "position": "absolute",
        "opacity": "0.95",
        "zIndex": "1",
    },
    button: {
        "width": "150px",
        "height": "30px",
        "position": "absolute",
        "zIndex": "1",
        "font-family": "'Source Sans Pro'", 
        "font-size": "12px",
        "color": "rgb(255, 255, 255)",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        "text-align": "center",
        "background-color": "rgba(0, 100, 150, 1)",
        "text-shadow": "rgb(214, 214, 214) 0px 0px 0px",
        "opacity": "0.95",
        "letter-spacing": "0px",
        "border-style": "solid",
        "border-width": "1px",
        "border-color": "rgba(0, 100, 150, 1)",
        "border-radius": "2px",
        "line-height": "100%",
        "overflow": "hidden"
    }
}