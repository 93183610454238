
module.exports = class EditorMustacheTemplates {

  constructor() {
    /** @type {string} */
    this.htmlMustacheTemplate

    /** @type {string} */
    this.cssMustacheTemplate
  }

}
