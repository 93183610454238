
module.exports = class EditorMenuElement {

  constructor() {

    /** @type {String} */
    this.id

    /** @type {String} */
    this.name
    
    /** @type {String} */
    this.type
    
    /** @type {String[]} */
    this.options
    
    /** @type {String[]} */
    this.metric
    
    /** @type {Object} */
    this.value

    /** @type {String} */
    this.selectedMetric
  }

}
