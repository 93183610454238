const Element = require("./Element");
const $ = require("jquery");

/** 
 * Class representing a layer in Ad-Creative Editor.
 */
module.exports = class Layer extends Element {

  /**
   * Create layer
   * @param {string} [id] - ID of element created to be
   */
  constructor(id) {
    super(null, "layer", id)
    global._app.layers.push(this);
    global.moveable.target = [];
    
    /** @type {Element[]} elements */
    this.elements = [];
  }

  /**
   * Get elements
   * @returns {Element[]} - Element list of layer
   */
  getElements() {
    return this.elements;
  }

  /**
   * Make element visible
   */
  show() {
    super.show();
    this.elements.forEach(element => {
      $("." + element.id).attr("data-moveable", "");
    });
  }

  /**
   * Make element invisible
   */
  hide() {
    super.hide();
    this.elements.forEach(element => {
      $("." + element.id).removeAttr("data-moveable");
    });
  }

  /**
   * Delete layer
   */
  delete() {
    let index = global._app.layers.findIndex((layer) => layer.id == this.id);
    if (index >= 0) {
      global._app.layers.splice(index, 1);
      document.querySelector("." + this.id).remove();
    }
  }

  /**
   * 
   * @param {Element} element 
   */
  addElement(element) {
    this.elements.push(element);
  }

  updateElementStyles() {
    this.hasClickTag = this.isValidUrl(this.clickTagUrl);
    this.elements.forEach(element => {
      element.updateStyle();
      element.generateAnimationCssText();
    });
  }

}
