import React, { useEffect, useState, useContext, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
import { Grid, Tooltip } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import ProjectService from "../../api/ProjectService";
import { timeDifference } from "../../api/UtilsService";
import CommonContext from "../../store/common-context";
import ProjectModal from "../commonUI/ProjectModal";
import logo from "../../assets/body/Bg3.jpg";
import ProjectCard from "../commonUI/ProjectCard";
import ProjectDeleteModal from "../commonUI/ProjectDeleteModal";
import ButtonPagination from "../commonUI/ButtonPagination";
import { menuListConfig } from "../../configs/config";
import CustomGrid from "../commonUI/CustomGrid";

const ArchivedScreen = () => {
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isShownDeleteModal, setIsShownDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [laoding, setLaoding] = useState(false);

  const {
    setIsLoading,
    setIsShownProjectModal,
    setProjectTitle,
    setProjectDescription,
    isShownProjectModal,
    projectTitle,
    projectDescription,
    enteredSearch,
  } = useContext(CommonContext);

  useEffect(() => {
    setPage(0);
  }, [enteredSearch]);

  const queryParams = {
    search: enteredSearch,
    statusList: menuListConfig.archivedStatus,
    sortBy: menuListConfig.projectSort,
    sortDirection: menuListConfig.listDirection,
    size: menuListConfig.itemCount + 1,
    page: page,
  };

  const getArchivedProjects = useCallback(() => {
    ProjectService.getProjectsList(queryParams).then((response) => {
      setLaoding(false);
      setTotalPage(response.data.totalPages);
      const archivedList = response?.data?.content;
      response.status === 200 && setArchivedProjects(archivedList);
    });
  }, [enteredSearch, page]);

  useEffect(() => {
    setLaoding(true);

    const searchDelay = setTimeout(() => {
      getArchivedProjects();
    }, 500);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [getArchivedProjects, enteredSearch, page]);

  const handleOpenDeleteModal = (cardIndex) => {
    setIsShownDeleteModal(true);
    const deletedProject = archivedProjects[cardIndex];
    setProjectTitle(archivedProjects[cardIndex].name);
    setSelectedProject(deletedProject);
  };

  const handleDelete = (creativeStatus) => {
    setIsLoading(true);
    ProjectService.deleteProjectById(selectedProject.id, creativeStatus).then(
      (response) => {
        response.status === 200 && getArchivedProjects();
        setIsLoading(false);
      }
    );
  };

  const handleUnarchive = (cardIndex) => {
    setIsLoading(true);
    const selectedItem = archivedProjects[cardIndex];
    const updatedObject = { ...selectedItem, status: "ACTIVE" };

    ProjectService.updateProjectById(selectedItem.id, updatedObject).then(
      (response) => {
        response.status === 200 && getArchivedProjects();
        setIsLoading(false);
      }
    );
  };

  const handleClickEdit = (cardIndex) => {
    const clickedProject = archivedProjects[cardIndex];
    setSelectedProject(clickedProject);
    setIsShownProjectModal(true);
    setProjectTitle(archivedProjects[cardIndex]?.name);
    setProjectDescription(archivedProjects[cardIndex]?.description);
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    const updatedObject = {
      ...selectedProject,
      name: projectTitle,
      description: projectDescription,
    };

    ProjectService.updateProjectById(selectedProject.id, updatedObject).then(
      (response) => {
        if (response.status === 200) {
          getArchivedProjects();
          setIsShownProjectModal(false);
          setProjectTitle("");
          setProjectDescription("");
          setIsLoading(false);
          getArchivedProjects();
        }
      }
    );
  };

  const listedItems = archivedProjects.map((project, index) => {
    const timeDiff = timeDifference(project.updatedOn);

    let creatives = project.creatives.filter(
      (creative) => creative.status === "ACTIVE"
    );

    let creativeCount = creatives.length;

    return (
      <Grid item key={project.id} xs={4} sm={4} md={3} xl={2}>
        <ProjectCard
          image={logo}
          project={project}
          creativeCount={
            <>
              <FilterNoneOutlinedIcon />
              <div>{creativeCount}</div>
            </>
          }
          editLabel={`Edited: ${timeDiff}`}
          iconSection={
            <>
              <Tooltip title="Edit" arrow>
                <EditIcon
                  onClick={() => handleClickEdit(index)}
                  className="icon-button"
                />
              </Tooltip>
              <Tooltip title="Unarchive Project" arrow>
                <UnarchiveIcon
                  onClick={() => handleUnarchive(index)}
                  className="icon-button"
                />
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <DeleteIcon
                  onClick={() => handleOpenDeleteModal(index)}
                  className="icon-button"
                />
              </Tooltip>
            </>
          }
        />
      </Grid>
    );
  });

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );

  const projectList = (
    <>
      <CustomGrid> {listedItems}</CustomGrid>

      <ButtonPagination
        page={page + 1}
        setPage={setPage}
        totalPage={totalPage}
      />
    </>
  );

  return (
    <>
      <div className="archived-screen">
        {laoding ? loadingContent : projectList}
      </div>
      {isShownProjectModal && (
        <ProjectModal editMod={true} handleSaveChanges={handleSaveChanges} />
      )}

      {isShownDeleteModal && (
        <ProjectDeleteModal
          onOpen={isShownDeleteModal}
          onHide={() => setIsShownDeleteModal(false)}
          modalTitle={`Delete Project: "${projectTitle}"`}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default ArchivedScreen;
