import http from "./http-common";

const DSP_BASE = "/dsp";

const uploadBannerToDSP = (uploadData) => {
  return http.post(DSP_BASE + `/creatives`, uploadData);
};

const getAgencies = (agenciesParams) => {
  return http.get(DSP_BASE + `/agencies`, {
    params: agenciesParams,
  });
};

const getAdvertiser = (agencyId, advertiseParams) => {
  return http.get(DSP_BASE + `/advertiser/${agencyId}`, {
    params: advertiseParams,
  });
};

const getCampaigns = (advertiseId, campaignParams) => {
  return http.get(DSP_BASE + `/campaign/${advertiseId}`, {
    params: campaignParams,
  });
};

const getLines = (campaignId, lineParams) => {
  return http.get(DSP_BASE + `/lines/${campaignId}`, {
    params: lineParams,
  });
};

const getAdsizes = (adsizeParams) => {
  return http.get(DSP_BASE + `/ad-size`, {
    params: adsizeParams,
  });
};

const DSPService = {
  getAgencies,
  getAdvertiser,
  getCampaigns,
  getLines,
  uploadBannerToDSP,
  getAdsizes,
};

export default DSPService;
