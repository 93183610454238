import { useEffect, useContext } from "react";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

import CreativeService from "../../api/CreativeService";
import CommonContext from "../../store/common-context";

const SharedRoute = () => {
  const { setIsShared, isShared } = useContext(CommonContext);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    setIsShared(true);

    const creativeData = {
      template: {
        id: params.templateId,
      },
    };

    CreativeService.createCreative(creativeData).then((response) => {
      if (response.status === 200) {
        history.push(`/template/${response.data.id}`);
      }
    });
  }, []);

  return <div className="share-router"></div>;
};

export default SharedRoute;
