import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import App from "./App";
import AuthProvider from "./store/AuthProvider";
import CommonProvider from "./store/CommonProvider";
import theme from "./theme";
import TemplateProvider from "./store/TemplateProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CommonProvider>
    <AuthProvider>
      <TemplateProvider>
        <HashRouter basename="/">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </HashRouter>
      </TemplateProvider>
    </AuthProvider>
  </CommonProvider>
);
