import React from "react";

import "./MiniPreviewMode.scss";
import { scaleCalculator } from "../../api/UtilsService";

const MiniPreviewMode = ({ bannerHTML, canvasSizes }) => {
  const { width, height } = canvasSizes;

  let scale = scaleCalculator(canvasSizes);

  /* console.log({ canvasSizes, scale }); */
  return (
    <div className="mini-preview-content">
      <div className="i-frame-layout" style={{ transform: `scale(${scale})` }}>
        <iframe
          width={width}
          height={height}
          className="i-frame"
          srcDoc={bannerHTML}
          title="HTML Frame-1"
        />
      </div>
    </div>
  );
};

export default MiniPreviewMode;
