const Element = require("./Element");
const Layer = require("./Layer");


/** 
 * Class representing a button element in Ad-Creative Editor.
 */
module.exports = class Button extends Element {

  /**
   * Create button
   * @param {Layer} layer - Layer to be rendered into
   * @param {string} [id] - ID of element created to be
   */
  constructor(layer, id) {
    super(layer, "button", id);
    this.isButton = true;
  }
}
