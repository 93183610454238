import { createTheme } from "@mui/material";
// import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: { main: "#33a7fb" },
    text: {
      // primary: "#fff",
      // secondary: "black",
    },
    white: "#fff",
  },

  typography: {
    fontFamily: "inherit",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    body2_medium: {
      fontSize: 10,
      lineHeight: 1.6,
      fontWeight: 400,
    },
    body2_small: {
      fontSize: 8,
      lineHeight: 1.6,
      fontWeight: 400,
    },
    button: {
      fontWeight: 500,
      textTransform: "none",
    },
  },

  components: {
    // Name of the component
    // MuiToggleButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       fontWeight: 500,
    //       textTransform: "lowercase",
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "white",
        },
      },
    },
  },
});

export default theme;
