import React from "react";

const CommonContext = React.createContext({
  hasError: {},
  setHasError: () => {},
  isLoading: false,
  setIsLoading: () => {},
  userInfo: {},
  setUserInfo: () => {},
  isShownProjectModal: false,
  setIsShownProjectModal: () => {},
  projectTitle: "",
  setProjectTitle: () => {},
  projectDescription: "",
  setProjectDescription: () => {},
  activeProjects: [],
  setActiveProjects: () => {},
  bannerTitle: "",
  setBannerTitle: () => {},
  isShownBannerModal: false,
  setIsShownBannerModal: () => {},
  selectedCategory: "",
  setSelectedCategory: () => {},
  selectedFormat: "",
  setSelectedFormat: () => {},
  checkedFilters: [],
  setCheckedFilters: () => {},
  isShared: false,
  setIsShared: () => {},
  enteredSearch: "",
  setEnteredSearch: () => {},
});

export default CommonContext;
