module.exports = ELEMENT_TEMPLATES = {
    layer:
        '<div data-element data-layer class="{{id}}"></div>',
    image:
        `<div class="{{id}}-wrapper">
            <img data-element data-image style="{{style}}" data-moveable class="{{id}}">
        </div>`,
    video:
        `<div class="{{id}}-wrapper">
            <video data-element class="{{id}}" data-video data-moveable>
                <p>Your browser doesn't support HTML video.</p>
            </video>
        </div>`,
    shape:
        `<div class="{{id}}-wrapper">
            <div data-element data-shape data-moveable class="{{id}}"></div>
        </div>`,
    text:
        `<div class="{{id}}-wrapper">
            <div data-element data-text contenteditable="plaintext-only" data-moveable class="{{id}}">TEXT</div>
        </div>`,
    button:
        `<div class="{{id}}-wrapper">
            <button data-element data-button data-moveable class="{{id}}">Click me!</button>
        </div>`,
}