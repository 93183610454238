import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import Modal from "../commonUI/Modal";
import TemplateContext from "../../store/template-context";
import DSPService from "../../api/DSPService";

const AdSizeModal = ({ onOpen, onHide, onCreate, templateName }) => {
  const [selectedSize, setSelectedSize] = useState("");
  const [adsizeData, setAdsizeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { setViewportSizes } = useContext(TemplateContext);

  useEffect(() => {
    setIsLoading((prevState) => !prevState);
    DSPService.getAdsizes({ page: 0, pageSize: 20 }).then((response) => {
      if (response.status === 200) {
        setSelectedSize(response.data.records.at(0).adSize);
        setAdsizeData(response.data.records);
        setIsLoading((prevState) => !prevState);
      }
    });
  }, []);

  useEffect(() => {
    const selectedItem = adsizeData.find(
      (item) => item.adSize === selectedSize
    );

    setViewportSizes({ ...selectedItem });
  }, [selectedSize]);

  const handleChangeSize = (e) => {
    setSelectedSize(e.target.value);
  };

  return (
    <Modal
      title="Select Adsize"
      onHide={onHide}
      onOpen={onOpen}
      acceptLabel="Create Banner"
      onAccept={onCreate}
    >
      <Typography color="text.secondary">{`Select banner size for ${templateName}:`}</Typography>

      {isLoading ? (
        <LinearProgress sx={{ width: "100%" }} />
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel>Ad Sizes</InputLabel>
          <Select
            value={selectedSize}
            label="Ad Sizes"
            onChange={handleChangeSize}
          >
            {adsizeData.map((option) => (
              <MenuItem
                key={option.adSize}
                size="small"
                value={option.adSize}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography fontWeight="bold">{option.adSizeSize}</Typography>
                <Typography color="text.secondary">
                  {option.adSizeName}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Modal>
  );
};

export default AdSizeModal;
