const Mustache = require("mustache")
const Pretty = require('pretty');

/**
 * Export service for getting HTML and up-to-date editor object
 */
module.exports = class ExportService {

  constructor() { }

  /**
   * Generate HTML
   * @returns {string} prettified HTML output of creative
   */
  generateHTML() {
    this.updateLayers();

    let template = this.getHTMLTemplate();

    let data = {
      layers: this.getLayers(),
      css: this.generateCSS(),
      menuOptions: JSON.stringify(this.getMenu()),
      title: this.getTitle(),
      dimentions: this.getOptions().canvasOptions
    }

    let renderedHTML = Mustache.render(template, data);

    return Pretty(renderedHTML);
  }

  /**
   * Generate CSS
   * @returns {string} CSS output of elements
   */
  generateCSS() {
    let template = this.getCSSTemplate();

    let rendered = Mustache.render(template, { layers: this.getLayers() });
    return rendered;
  }

  updateLayers() {
    let layers = this.getLayers();

    layers.forEach(layer => {
      layer.updateElementStyles();
    })
  }

  /**
   * Get EditorObject JSON
   * @returns {EditorObject} editor object
   */
  exportJson() {
    let app = this.getApp();
    let layers = app.layers;

    layers.forEach(layer => {
      layer.updateElementStyles();
    })

    return app;
  }

  /**
   * Get global EditorObject
   * @returns {EditorObject}
   */
  getApp() {
    return global._app;
  }

  /**
   * Get layers
   * @returns {Layer[]} - List of layers
   */
  getLayers() {
    return global._app.layers;
  }

  /**
   * Get templates
   * @returns {EditorMustacheTemplates} - Template strings
   */
  getTemplate() {
    let app = this.getApp();
    return app.options.mustacheTemplates;
  }

  /**
   * Get HTML template
   * @returns {string} - HTML template string
   */
  getHTMLTemplate() {
    let app = this.getApp();
    return app.options.mustacheTemplates.htmlMustacheTemplate;
  }

  /**
   * Get CSS template
   * @returns {string} - CSS template string
   */
  getCSSTemplate() {
    let app = this.getApp();
    return app.options.mustacheTemplates.cssMustacheTemplate;
  }

  /**
   * Get menu object
   * @returns {Object} - Menu object
   */
  getMenu() {
    let menu = this.getApp().options.menu;

    let map = {};

    let extendedMenu = menu.simple.concat(menu.advanced);

    extendedMenu.forEach(element => {
        map[element.id] = element.selectedMetric ? element.value + element.selectedMetric: element.value;
    });

    return map;
  }

  /**
   * Get title of template
   * @returns {string} - name of template
   */
  getTitle() {
    return this.getApp().options.layerOptions.parentName;
  }

  getOptions() {
    return this.getApp().options;;
  }

}
