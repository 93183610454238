export const fastLoginEndPoint = "https://hizligiris.com.tr/hizligiris";

export const menuListConfig = {
  activeStatus: "ACTIVE",
  archivedStatus: "ARCHIVED",
  itemCount: 59,
  listDirection: "DESC",
  bannerSort: "lastEdit",
  projectSort: "updatedOn",
};

export const currentDomain =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_DOMAIN
    : process.env.REACT_APP_DEVELOPMENT_DOMAIN;

export const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_DEVELOPMENT_API_URL;
