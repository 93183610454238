
const EditorOptions = require("./EditorOptions");
const Layer = require("../Layer");


 module.exports = class EditorObject {

  constructor() {

    /** @type {EditorOptions} */
    this.options = new EditorOptions();

    /** @type {Layer} */
    this.layers;
  }

}
