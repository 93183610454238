

module.exports = class EditorCanvasOptions {

  constructor() {

    /** @type {String} */
    this.width = "300";

    /** @type {String} */
    this.height = "300";

  }

}



