module.exports = MUSTACHE_TEMPLATES = {
  editor_template:
    `
      <div class="reset"></div>
      <div class="guides horizontal"></div>
      <div class="guides vertical"></div>
      <div class="scroll horizontal"></div>
      <div class="viewer">
        <div id="viewport"></div>
      </div>
   `
}
