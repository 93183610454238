import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Divider from "@mui/material/Divider";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import UploadIcon from "@mui/icons-material/Upload";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import AuthContext from "../../store/auth-context";
import AuthService from "../../api/AuthService";
import CommonContext from "../../store/common-context";
import buttonImage from "../../assets/logo/fastLoginButton.png";
import ProjectModal from "../commonUI/ProjectModal";
import { currentDomain } from "../../configs/config";
import adDesignLogo from "../../assets/logo/ad-creative-logo.png";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const redirectUri = `${currentDomain}`;

const SideBar = () => {
  const [isToggledProjects, setIsToggledProjects] = useState(false);
  const [isShownActive, setIsShownActive] = useState(false);
  const [isShownSandbox, setIsShownSandbox] = useState(false);
  const [isShownArchived, setIsShownArchived] = useState(false);
  const [isShownShared, setIsShownShared] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // const fileInput = useRef(null);

  const history = useHistory();

  const location = useLocation();

  const { loginHandler, logoutHandler, isLoggedIn, token } =
    useContext(AuthContext);

  const {
    setIsLoading,
    userInfo,
    setUserInfo,
    setIsShownProjectModal,
    isShownProjectModal,
  } = useContext(CommonContext);

  useEffect(() => {
    token &&
      AuthService.getUserInfo().then((response) => {
        const userData = response.data;
        setUserInfo(userData);
      });
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    if (location.pathname === "/home/active") {
      setIsShownActive(1);
      setIsShownSandbox(0);
      setIsShownArchived(0);
      setIsShownShared(0);
    } else if (location.pathname === "/home/sandbox") {
      setIsShownActive(0);
      setIsShownSandbox(1);
      setIsShownArchived(0);
      setIsShownShared(0);
    } else if (location.pathname === "/home/archived") {
      setIsShownActive(0);
      setIsShownSandbox(0);
      setIsShownArchived(1);
      setIsShownShared(0);
    } else if (location.pathname === "/home/shared") {
      setIsShownActive(0);
      setIsShownSandbox(0);
      setIsShownArchived(0);
      setIsShownShared(1);
    } else {
      setIsShownActive(0);
      setIsShownSandbox(0);
      setIsShownArchived(0);
      setIsShownShared(0);
    }
  }, [location.pathname]);

  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!+%/()=-*";

  const getLogin = () => {
    let state = "";
    let nonce = "";
    for (let i = 0; i < 70; i++) {
      state += chars.charAt(Math.floor(Math.random() * chars.length));
      nonce += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    var login = window.open(
      `https://hizligiris.com.tr/hizligiris/oauth/authorize?client_id=AdplatformApp&scope=phone%20email%20openid&redirect_uri=${redirectUri}&response_type=code&state=${state}&nonce=${nonce}&prompt=login&acr_values=3&display=page&ui_locales=tr&claims_locales=tr`,
      "popup",
      "width=700",
      "height=700"
    );
    listenEvent(login);
  };

  const postCode = (code, redirectUri) => {
    setIsLoading(true);
    AuthService.getToken(code, redirectUri).then((response) => {
      let token = response.data.token;
      loginHandler(token);
    });
  };

  const listenEvent = (login) => {
    // This function is used to listen to the Popup screen
    let i = 0;
    const interval = setInterval(() => {
      if (i >= 1200) {
        login.close();
        clearInterval(interval);
      }
      i += 1;

      if (login.location.search) {
        window.location.pathname = login.location.pathname;
        let params = new URLSearchParams(login.location.search);
        // let state = params.get("state");
        let code = params.get("code");
        localStorage.setItem("code", params.get("code"));
        // if (state ||code) {
        if (localStorage.getItem("code")) {
          // setStateCode(state);
          login.close();
          postCode(code, redirectUri);
          clearInterval(interval);
        }
      }
    }, 500);
  };

  const handleLogout = () => {
    logoutHandler();
  };

  const openNewButton = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleFileSelect = (event) => {
  //   const file = event.target.files[0];

  //   setIsLoading(true);
  //   console.log({ file });

  //   let isStandard = file.type.includes("image", "jpeg");

  //   const templateId = 6;
  //   const projectId = null;
  //   const projectTitle = "Standard";

  //   const creativeData = {
  //     title: projectTitle,
  //     template: {
  //       id: templateId,
  //     },
  //     project: {
  //       id: projectId,
  //     },
  //   };

  //   isStandard &&
  //     CreativeService.createCreative(creativeData).then((response) => {
  //       response.status === 200 && console.log({ response });
  //     });

  //   history.push(`/template/${projectTitle}`);

  //   // atlasEditor.services.ImageService.addImage(
  //   //   file,
  //   //   atlasEditor.services.LayerService.layers[0]
  //   // );

  //   // layers[0] = atlasEditor.services.LayerService.layers[0];

  //   // setTimeout(() => {
  //   //   setLayers([...layers]);
  //   // }, 50);

  //   // setIsLoading(false);
  //   setAnchorEl(null);
  // };

  // const clickUploadBanner = () => {
  //   fileInput.current.click();
  // };

  const handleOpenProjectModal = () => {
    setIsShownProjectModal(true);
    setAnchorEl(null);
  };

  const newButtonContent = isLoggedIn ? (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={openNewButton}
      onClose={handleClose}
    >
      <Link style={{ color: "rgb(55, 65, 81)" }} to={"/home/templates"}>
        <MenuItem onClick={handleClose} disableRipple>
          <ViewCarouselIcon />
          Banner
        </MenuItem>
      </Link>
      <MenuItem onClick={handleOpenProjectModal} disableRipple>
        <AccountTreeIcon />
        Project
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={handleClose} disableRipple>
        <UploadIcon />
        Upload Banner
      </MenuItem>
    </StyledMenu>
  ) : (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={openNewButton}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose} disableRipple>
        <UploadIcon />
        Upload Banner
      </MenuItem>
    </StyledMenu>
  );

  const fakeLoginHandler = () => {
    AuthService.fakeLogin().then((response) => {
      if (response.status === 200) {
        let token = response.data.token;
        loginHandler(token);
      }
    });
  };

  return (
    <>
      <div className="side-bar-container">
        <div className="side-bar">
          <div className="brand-box">
            {isLoggedIn ? (
              <div
                className="logo-section"
                onClick={() => history.push("/home")}
              >
                <img src={adDesignLogo} alt="AdCreative Logo" />
              </div>
            ) : (
              <button type="button" className="login-button" onClick={getLogin}>
                <img src={buttonImage} />
              </button>
            )}

            <hr className="line" />
          </div>

          <div className="list-box">
            <ul style={{ paddingLeft: "0" }}>
              <div className="new-button-section">
                <Button
                  id="demo-customized-button"
                  aria-controls={
                    openNewButton ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openNewButton ? "true" : undefined}
                  className="new-button"
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  New
                </Button>

                {newButtonContent}
              </div>
              {isLoggedIn && (
                <li className={isToggledProjects ? "events-box" : null}>
                  <button
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    className="list-text down-bar"
                    onClick={() => {
                      setIsToggledProjects(!isToggledProjects);
                    }}
                  >
                    <div className="label-section">
                      <AccountTreeIcon />
                      <p>Projects</p>
                    </div>

                    {isToggledProjects ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </button>
                  {isToggledProjects ? (
                    <ul className="low-list" style={{ padding: "0" }}>
                      <Link to={"/home/active"}>
                        <li
                          className={
                            isShownActive ? "low-active" : "low-content"
                          }
                        >
                          <button
                            className="list-text down-bar"
                            style={{
                              fontSize: "0.8rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsShownActive(!isShownActive);
                            }}
                          >
                            Active
                            {isShownActive ? (
                              <div
                                className="hover-icon"
                                onClick={() => {
                                  history.goBack();
                                }}
                              >
                                <KeyboardArrowRightIcon />
                              </div>
                            ) : (
                              <div className="hover-icon">
                                <KeyboardArrowLeftIcon />
                              </div>
                            )}
                          </button>
                        </li>
                      </Link>
                      <Link to={"/home/sandbox"}>
                        <li
                          className={
                            isShownSandbox ? "low-active" : "low-content"
                          }
                        >
                          <button
                            className="list-text down-bar"
                            style={{
                              fontSize: "0.8rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsShownSandbox(!isShownSandbox);
                            }}
                          >
                            Sandbox
                            {isShownSandbox ? (
                              <div className="hover-icon">
                                <KeyboardArrowRightIcon />
                              </div>
                            ) : (
                              <div className="hover-icon">
                                <KeyboardArrowLeftIcon />
                              </div>
                            )}
                          </button>
                        </li>
                      </Link>
                      <Link to={"/home/archived"}>
                        <li
                          className={
                            isShownArchived ? "low-active" : "low-content"
                          }
                        >
                          <button
                            className="list-text down-bar"
                            style={{
                              fontSize: "0.8rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsShownArchived(!isShownArchived);
                            }}
                          >
                            Archived
                            {isShownArchived ? (
                              <div className="hover-icon">
                                <KeyboardArrowRightIcon />
                              </div>
                            ) : (
                              <div className="hover-icon">
                                <KeyboardArrowLeftIcon />
                              </div>
                            )}
                          </button>
                        </li>
                      </Link>
                      <Link to={"/home/shared"}>
                        <li
                          className={
                            isShownShared ? "low-active" : "low-content"
                          }
                        >
                          <button
                            className="list-text down-bar"
                            style={{
                              fontSize: "0.8rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsShownShared(!isShownShared);
                            }}
                          >
                            Shared
                            {isShownShared ? (
                              <div className="hover-icon">
                                <KeyboardArrowRightIcon />
                              </div>
                            ) : (
                              <div className="hover-icon">
                                <KeyboardArrowLeftIcon />
                              </div>
                            )}
                          </button>
                        </li>
                      </Link>
                    </ul>
                  ) : null}
                </li>
              )}

              <li
                className={
                  history.location.pathname === "/home/templates"
                    ? "path-templates"
                    : null
                }
              >
                <Link to={"/home/templates"}>
                  <ViewCarouselIcon />
                  <p>Templates</p>
                </Link>
              </li>

              <li
                className={
                  history.location.pathname === "/home/help"
                    ? "path-templates"
                    : null
                }
              >
                <Link to={"/home/help"}>
                  <HelpOutlineIcon />
                  <p>Help</p>
                </Link>
              </li>
              {!isLoggedIn && (
                <li>
                  <Button onClick={fakeLoginHandler} variant="contained">
                    Fake Login
                  </Button>
                </li>
              )}
            </ul>
            {isLoggedIn && (
              <div
                className="log-out"
                onClick={() => {
                  handleLogout();
                }}
              >
                <LogoutIcon />
                <p>Log out</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {isShownProjectModal && (
        <ProjectModal
          setIsToggledProjects={setIsToggledProjects}
          editMod={false}
        />
      )}
    </>
  );
};

export default SideBar;
