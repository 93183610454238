import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Tooltip, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import "./ProjectDetails.scss";
import CreativeService from "../../api/CreativeService";
import BuilderMode from "../builderMode";
import { calculateTime } from "../../api/UtilsService";
import CommonContext from "../../store/common-context";
import BannerTitleEdit from "../commonUI/BannerTitleEdit";
import FileInput from "../commonUI/FileInput";
import ButtonPagination from "../commonUI/ButtonPagination";
import { menuListConfig } from "../../configs/config";
import CustomGrid from "../commonUI/CustomGrid";

const ProjectDetails = () => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [laoding, setLaoding] = useState(false);

  const {
    isShownBannerModal,
    setIsShownBannerModal,
    setBannerTitle,
    bannerTitle,
    setIsLoading,
    enteredSearch,
  } = useContext(CommonContext);

  const params = useParams();

  const history = useHistory();

  useEffect(() => {
    setPage(0);
  }, [enteredSearch]);

  const projectId = params?.projectId;

  const queryParams = {
    projectId: projectId,
    statusList: menuListConfig.activeStatus,
    search: enteredSearch,
    sortBy: menuListConfig.bannerSort,
    sortDirection: menuListConfig.listDirection,
    size: menuListConfig.itemCount,
    page: page,
  };

  const getBannersOfProject = useCallback(() => {
    CreativeService.getCreatives(queryParams).then((response) => {
      if (response.status === 200) {
        setTotalPage(response.data.totalPages);
        setLaoding(false);
        const bannerList = response.data.content;
        setBanners(bannerList);
      }
    });
  }, [enteredSearch, page]);

  useEffect(() => {
    setLaoding(true);

    const searchDelay = setTimeout(() => {
      getBannersOfProject();
    }, 500);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [getBannersOfProject, enteredSearch, page]);

  const handleSaveChanges = () => {
    setIsLoading(true);
    const updatedObject = { ...selectedBanner, title: bannerTitle };
    CreativeService.updateCreativeById(selectedBanner?.id, updatedObject).then(
      (response) => {
        if (response.status === 200) {
          getBannersOfProject();
          setIsShownBannerModal(false);
          setBannerTitle("");
          setIsLoading(false);
        }
      }
    );
  };

  const handleClickEdit = (bannerIndex) => {
    setIsShownBannerModal(true);
    const selectedCreative = banners[bannerIndex];
    setSelectedBanner(selectedCreative);
    setBannerTitle(banners[bannerIndex]?.title);
  };

  const handleDelete = (bannerIndex) => {
    setIsLoading(true);
    const creativeId = banners[bannerIndex]?.id;

    CreativeService.deleteCreative(creativeId).then((response) => {
      response.status === 200 && getBannersOfProject();
    });

    setIsLoading(false);
  };

  const handleFileSelect = (file) => {
    // setIsLoading(true);
    // console.log({ file });

    let isStandard = file.type.includes("image", "jpeg");

    const templateId = 6;
    const projectId = params.projectId;

    const creativeData = {
      template: {
        id: templateId,
      },
      project: {
        id: projectId,
      },
    };

    isStandard &&
      CreativeService.createCreative(creativeData).then((response) => {
        response.status === 200 &&
          history.push(`/template/${response.data.id}`);
      });

    // atlasEditor.services.ImageService.addImage(
    //   file,
    //   atlasEditor.services.LayerService.layers[0]
    // );

    // layers[0] = atlasEditor.services.LayerService.layers[0];

    // setTimeout(() => {
    //   setLayers([...layers]);
    // }, 50);

    // setIsLoading(false);
  };

  const handleClickUnassign = (bannerIndex) => {
    const selectedCreative = banners[bannerIndex];
    const updatedObject = { ...selectedCreative, project: null };
    CreativeService.updateCreativeById(selectedCreative.id, updatedObject).then(
      (response) => {
        response.status === 200 && getBannersOfProject();
      }
    );
  };

  const handleClickDownload = (bannerIndex) => {
    const creativeId = banners[bannerIndex]?.id;
    const isCompressed = false;
    CreativeService.downloadCreative(creativeId, isCompressed).then(
      (response) => {
        response.status === 200 && window.open(response.data);
      }
    );
  };

  const listedItems = banners.map((banner, index) => {
    const timeDiff = calculateTime(banner.lastEdit);
    return (
      <Grid key={banner.id} item xs={4} sm={4} md={3} xl={2}>
        <BuilderMode
          color="blue"
          title={banner.title}
          moduleText={timeDiff}
          cardType="existedBanner"
          bannerId={banner.id}
          iconSection={
            <>
              <Tooltip title="Edit Title" arrow>
                <EditIcon onClick={() => handleClickEdit(index)} />
              </Tooltip>
              <Tooltip title="Download" arrow>
                <FileDownloadIcon onClick={() => handleClickDownload(index)} />
              </Tooltip>

              <Tooltip title="Unassign from Project" arrow>
                <UnarchiveIcon onClick={() => handleClickUnassign(index)} />
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <DeleteIcon onClick={() => handleDelete(index)} />
              </Tooltip>
            </>
          }
        />
      </Grid>
    );
  });

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );

  const bannerList = (
    <>
      <CustomGrid>
        <Grid item xs={4} sm={4} md={3} xl={2}>
          <FileInput onFileSelect={handleFileSelect} />
        </Grid>
        {listedItems}
      </CustomGrid>

      <ButtonPagination
        page={page + 1}
        setPage={setPage}
        totalPage={totalPage}
      />
    </>
  );

  return (
    <>
      <div className="single-project-screen">
        {laoding ? loadingContent : bannerList}
      </div>
      {isShownBannerModal && (
        <BannerTitleEdit handleSaveChanges={handleSaveChanges} />
      )}
    </>
  );
};

export default ProjectDetails;
