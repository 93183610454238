import { Container, Grid } from "@mui/material";

const CustomGrid = ({ children }) => {
  return (
    <Container
      sx={{ padding: "32px !important", maxWidth: "1850px !important" }}
    >
      <Grid
        container
        rowSpacing={{ xs: 4, sm: 4, m: 4, lg: 5, xl: 6 }}
        columnSpacing={{ xs: 4, sm: 4, m: 4, lg: 5, xl: 6 }}
      >
        {children}
      </Grid>
    </Container>
  );
};

export default CustomGrid;
