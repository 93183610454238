import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./Main.scss";
import Navbar from "./components/navbar";
import SideBar from "./components/sideBar";
import ActiveProjects from "./components/activeProjects";
import Templates from "./components/templates";
import LivePreview from "./components/livePreview/LivePreview";
import LazyLoading from "./components/commonUI/LazyLoading";
import AuthContext from "./store/auth-context";
import Loading from "./components/commonUI/Loading";
import Error from "./components/commonUI/Error";
import { registerIntercepts } from "./api/http-common";
import { registerInterceptsForm } from "./api/http-multidata";
import CommonContext from "./store/common-context";
import SandboxScreen from "./components/sandbox";
import ArchivedScreen from "./components/archived/ArchivedScreen";
import SharedScreen from "./components/shared/SharedScreen";
import ProjectDetails from "./components/projectDetails/ProjectDetails";
import SharedRoute from "./components/templates/SharedRoute";

const Template = lazy(() => import("./components/template"));

const App = () => {
  const { hasError, setHasError, isLoading } = useContext(CommonContext);

  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    registerIntercepts(setHasError);
    registerInterceptsForm(setHasError);
  }, [setHasError]);

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home">
          <div
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
          >
            <SideBar />
            <div className="home-page">
              <Navbar />
              {isLoggedIn ? (
                <Switch>
                  <Route exact path={"/home/templates"} component={Templates} />
                  <Route path={"/home/active"} component={ActiveProjects} />
                  <Route path={"/home/sandbox"} component={SandboxScreen} />
                  <Route path={"/home/archived"} component={ArchivedScreen} />
                  <Route path={"/home/shared"} component={SharedScreen} />
                  <Route
                    exact
                    path="/home/projects/:projectId"
                    component={ProjectDetails}
                  />
                  <Route
                    path="/home/projects/:projectId/create-banner"
                    component={Templates}
                  />
                  <Route path={"/home/help"}>
                    <h6>HELP PAGE</h6>
                  </Route>
                  <Route
                    path={"/home/create-banner/:templateId"}
                    component={SharedRoute}
                  />
                  {/* <Route path="*">
                    <Redirect to="/home/templates"></Redirect>
                  </Route> */}
                </Switch>
              ) : (
                <Switch>
                  <Route exact path={"/home/templates"} component={Templates} />
                  <Route path={"/home/help"}>
                    <h6>HELP PAGE</h6>
                  </Route>
                  <Route
                    path={"/home/create-banner/:templateId"}
                    component={SharedRoute}
                  />
                  <Route path="*">
                    <Redirect to="/home/templates"></Redirect>
                  </Route>
                </Switch>
              )}
              <footer style={{ height: "42px", width: "100%" }} />
            </div>
          </div>
          {/* <Redirect from={"/home"} to={"/home/templates"} /> */}
        </Route>

        <Route
          path={"/home/create-banner/:templateId"}
          component={SharedRoute}
        />

        <Route path="/template/:id">
          <Template />
        </Route>
        <Route path="/livepreview">
          <LivePreview />
        </Route>

        {/* <Route path="*">
          <Redirect to="/home/templates"></Redirect>
        </Route> */}
      </Switch>
      {hasError.open && <Error />}
      {isLoading && <Loading />}
    </Suspense>
  );
};

export default App;
