import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

import Modal from "./Modal";
import CommonContext from "../../store/common-context";
import ProjectService from "../../api/ProjectService";

const ProjectModal = ({ editMod, handleSaveChanges }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    setIsShownProjectModal,
    isShownProjectModal,
    projectTitle,
    setProjectTitle,
    projectDescription,
    setProjectDescription,
    setActiveProjects,
  } = useContext(CommonContext);

  const history = useHistory();

  const location = useLocation();

  let formIsValid = !!projectTitle?.trim().length > 0;

  let buttonDisabled = formIsValid ? false : true;

  const getActiveProjects = () => {
    setIsLoading(true);
    const statusList = "ACTIVE";
    const queryParams = { statusList: statusList };
    ProjectService.getProjectsList(queryParams).then((response) => {
      if (response.status === 200) {
        const activeItems = response.data.content;
        setActiveProjects(activeItems);
      }
    });
    setIsLoading(false);
  };

  const handleCreateProject = () => {
    setIsLoading(true);

    ProjectService.createProject(projectTitle, projectDescription).then(
      (response) => {
        if (response.status === 200) {
          getActiveProjects();
          setIsLoading(false);
          setIsShownProjectModal(false);
          location !== "/home/active" && history.push("/home/active");
          // props.setIsToggledProjects(true);
        }
      }
    );

    setProjectTitle("");
    setProjectDescription("");
    setIsLoading(false);
  };

  const loadingContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "14rem",
        margin: "4rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <p>{editMod ? "Updating Project..." : "Creating Project..."}</p>
    </Box>
  );

  const inputContents = (
    <>
      <TextField
        label="Project Title"
        value={projectTitle}
        onChange={(event) => {
          setProjectTitle(event.target.value);
        }}
      />
      <TextField
        label="Project Description"
        value={projectDescription}
        onChange={(event) => {
          setProjectDescription(event.target.value);
        }}
        multiline={true}
        minRows={3}
      />
    </>
  );

  const handleAccept = () =>
    editMod ? handleSaveChanges() : handleCreateProject();

  return (
    <Modal
      onOpen={isShownProjectModal}
      onHide={() => {
        setProjectTitle("");
        setProjectDescription("");
        setIsShownProjectModal(false);
      }}
      title={editMod ? "Update Project Data" : "New Project"}
      acceptLabel="Save"
      onAccept={handleAccept}
      isDisabled={buttonDisabled}
    >
      {isLoading ? loadingContent : inputContents}
    </Modal>
  );
};

export default ProjectModal;
