import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";

import "./ProjectCard.scss";

const ProjectCard = (props) => {
  const history = useHistory();

  return (
    <Card className="card-container" elevation={6}>
      <CardActionArea
        onClick={() => history.push(`/home/projects/${props.project?.id}`)}
      >
        <CardMedia
          component="img"
          height="75"
          image={props.image}
          alt="Card Photo"
          className="card-media"
        />
        <CardContent className="card-content">
          <Typography
            gutterBottom
            className="card-project-name"
            component="div"
          >
            {props.project?.name}
          </Typography>

          <Typography className="card-edit-label" gutterBottom component="div">
            {props.editLabel}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Typography
          className="card-project-description"
          variant="body2"
          color="text.secondary"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {props.project?.description}
        </Typography>
      </CardActions>
      <CardActions className="card-actions" sx={{ p: 1.5 }}>
        <div className="creative-count">{props.creativeCount}</div>
        <div className="action-buttons">{props.iconSection}</div>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
