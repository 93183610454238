
module.exports = class EditorLayerOptions {

  constructor() {
    /** @type {String} */
    this.nameTemplate

    /** @type {Number} */
    this.counter

    /** @type {Number} */
    this.maxLayerCount

      /** @type {String} */
      this.parentName
  }

}
