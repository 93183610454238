

const EditorCanvasOptions = require("./EditorCanvasOptions");
const EditorElementOptions = require("./EditorElementOptions");
const EditorLayerOptions = require("./EditorLayerOptions");
const EditorMustacheTemplates = require("./EditorMustacheTemplates");
const EditorMenu = require("./EditorMenu");


module.exports = class EditorOptions {

  constructor() {

    /** @type {EditorCanvasOptions} */
    this.canvasOptions = new EditorCanvasOptions();

    /** @type {EditorElementOptions} */
    this.elementOptions

    /** @type {EditorLayerOptions} */
    this.layerOptions

    /** @type {EditorMustacheTemplates} */
    this.mustacheTemplates

    /** @type {EditorMenu} */
    this.menu

  }


}
