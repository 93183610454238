import React, { useContext } from "react";
import { TextField } from "@mui/material";

import CommonContext from "../../store/common-context";
import Modal from "./Modal";

const BannerTitleEdit = ({ handleSaveChanges }) => {
  const {
    bannerTitle,
    setBannerTitle,
    setIsShownBannerModal,
    isShownBannerModal,
  } = useContext(CommonContext);

  return (
    <Modal
      onOpen={isShownBannerModal}
      onHide={() => setIsShownBannerModal(false)}
      title="Update Banner Title"
      onAccept={handleSaveChanges}
      acceptLabel="Save"
    >
      <TextField
        label="Banner Title"
        value={bannerTitle}
        onChange={(event) => {
          setBannerTitle(event.target.value);
        }}
      />
    </Modal>
  );
};

export default BannerTitleEdit;
