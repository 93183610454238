import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import "./ProjectDeleteModal.scss";
import Modal from "./Modal";
import CommonContext from "../../store/common-context";

const ProjectDeleteModal = ({ modalTitle, onOpen, onHide, handleDelete }) => {
  const { setProjectTitle } = useContext(CommonContext);
  return (
    <Modal title={modalTitle} onOpen={onOpen} onHide={onHide} hideActions>
      <Typography variant="h6">Please choose project deletion way.</Typography>
      <div className="modal-actions">
        <Button
          size="small"
          variant="contained"
          className="modal-close"
          onClick={onHide}
        >
          close
        </Button>
        <Button
          size="small"
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => {
            handleDelete(false);
            setProjectTitle("");
            onHide();
          }}
        >
          Delete only Project
        </Button>
        <Button
          size="small"
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => {
            handleDelete(true);
            setProjectTitle("");
            onHide();
          }}
        >
          Delete Project and Banner Inside
        </Button>
      </div>
    </Modal>
  );
};

export default ProjectDeleteModal;
