const Button = require("../models/Button");
const Image = require("../models/Image");
const Layer = require("../models/Layer");
const Shape = require("../models/Shape");
const Text = require("../models/Text");
const Video = require("../models/Video");
const EditorLayerOptions = require("../models/editor/EditorLayerOptions");

/**
 * Service layer for elements
 */
module.exports = class ElementService {

    /**
     * Create element service
     */
    constructor() {

    }

    /**
     * Add a button into layer
     * @param {Layer} layer - Layer to be rendered into
     * @returns {Button} - Button that created
     */
    addButton(layer, id) {
        let button = new Button(layer, id);
        return button;
    }

    /**
     * Add an image into layer
     * @param {File} file - Layer to be rendered into
     * @param {Layer} layer - Layer to be rendered into
     * @returns {Image} - Image that created
     */
    addImageFromFile(layer, file) {
        let image = new Image(layer);
        image.renderFromFile(file)
        return image;
    }

    /**
     * 
     * @param {Layer} layer 
     * @param {string} url 
     */
    addImageFromUrl(layer, url, id) {
        let image = new Image(layer, id);
        image.renderFromUrl(url);
        return image;
    }

    /**
     * 
     * @param {Layer} layer 
     * @param {string} url 
     */
    addVideoFromUrl(layer, url, id) {
        let video = new Video(layer, id);
        video.renderFromUrl(url);
        return video;
    }

    /**
     * Add layer into project
     * @returns {Layer} - Layer that created
     */
    addLayer() {
        /** @type {Layer[]} */
        let layers = global._app.layers;
        /** @type {EditorLayerOptions} */
        let layerOptions = global._app.options.layerOptions;
        
        if(layers.length < layerOptions.maxLayerCount) {
            let layer = new Layer();
            layer.setName(layerOptions.nameTemplate.replace("%d", ++layerOptions.counter));
            return layer;
        } else {
            return null;
        }

    }

    /**
     * Add a shape into layer
     * @param {Layer} layer - Layer to be rendered into
     * @returns {Shape} - Shape that created
     */
    addShape(layer, id) {
        let shape = new Shape(layer, id);
        return shape;
    }

    /**
     * Add a text into layer
     * @param {Layer} layer - Layer to be rendered into
     * @returns {Text} - Text that created
     */
    addText(layer, id) {
        let text = new Text(layer, id);
        return text;
    }

    /**
     * Get layers
     * @returns {Layer[]} - List of layers
     */
    getLayers() {
        return global._app.layers;
    }

}
